import React, { FC } from 'react';
import { SmsValidationData } from 'routes/SendNotifications/content/validation/ValidationTab';
import { Grid, Stack, Typography } from '@mui/material';
import { getSuccessOrFailureText } from 'helpers/templateValidation';
import { TransTitle } from 'i18n/trans/title';
import { TextField } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';

interface SmsValidationProps {
  data: SmsValidationData;
  index: number;
}
export const SmsValidation: FC<SmsValidationProps> = ({ data, index }) => {
  return (
    <>
      <Stack direction="row" sx={{ mb: 2 }}>
        <Typography fontWeight={900}>
          <TransTitle i18nKey="smsTemplate" />
          {':'}
        </Typography>
        {getSuccessOrFailureText(data.contentError)}
      </Stack>

      <Grid container spacing={2} columns={2}>
        <Grid item xs={1}>
          <TextField
            name={`notificationsByLanguage[${index}].smsSenderName`}
            defaultValue={data.senderName}
            disabled
            label={
              <>
                <TransField i18nKey="smsSenderName" />
                {':'}
              </>
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            name={`notificationsByLanguage[${index}].smsContent`}
            defaultValue={data.content}
            disabled
            multiline
            rows={5}
            label={
              <>
                <TransField i18nKey="smsContent" />
                {':'}
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
