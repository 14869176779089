import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { Passenger } from 'dto/passengers';
import {
  ReAccommodateTripsFilter,
  PostReAccommodateTripsResponse,
  GetReAccommodateDetailedTripResponse,
  PassengerReAccommodateStep,
  ReAccommodateTrip,
} from 'dto/reAccommodate';
import {
  getReAccommodateNewDetailedTrip,
  getReAccommodateNewTripsList,
  setReAccommodateActiveStep,
  setReAccommodateCurrentTripsList,
  setReAccommodateNewTripsFilter,
  resetAccommodate,
} from 'features/reAccommodate/reAccommodateActions';

export interface CommonState {
  currentTripsList: Array<Passenger>;
  activeStep: PassengerReAccommodateStep;
  newTripsFilter: Partial<ReAccommodateTripsFilter>;
  newTripsList?: PostReAccommodateTripsResponse;
  newDetailedTrips: Record<
    ReAccommodateTrip['id'],
    GetReAccommodateDetailedTripResponse
  >;
}

const initialState: CommonState = {
  activeStep: 'selectTrip',
  currentTripsList: [],
  newDetailedTrips: {},
  newTripsFilter: {},
};

export const reAccommodateReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetAccommodate, (state) => {
      state.currentTripsList = [];
      state.activeStep = 'selectTrip';
      state.newTripsFilter = {};
      state.newTripsList = undefined;
      state.newDetailedTrips = {};
    })
    .addCase(setReAccommodateCurrentTripsList, (state, action) => {
      state.currentTripsList = action.payload;
    })
    .addCase(setReAccommodateActiveStep, (state, action) => {
      state.activeStep = action.payload;
    })
    .addCase(setReAccommodateNewTripsFilter, (state, action) => {
      state.newTripsFilter = action.payload;
    })
    .addCase(getReAccommodateNewTripsList.fulfilled, (state, action) => {
      state.newTripsList = action.payload;
    })
    .addCase(
      getReAccommodateNewDetailedTrip.fulfilled,
      (
        state: CommonState,
        action: PayloadAction<GetReAccommodateDetailedTripResponse>
      ) => {
        const { tripId } = action.payload;
        state.newDetailedTrips[tripId] = action.payload;
      }
    );
});
