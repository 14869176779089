import {
  Button,
  Loadable,
  Modal,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useRowSelect, useTable } from 'react-table';
import { Icon } from '@fleet/shared/mui';
import { Box, CardContent, Divider, Stack, Typography } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { TransTitle } from 'i18n/trans/title';
import { fetchStops } from 'features/passengers/passengersService';
import { StopSearchForm } from 'components/StopField/StopSearchForm';
import type { Classifier } from '@fleet/shared/dto/classifier';

interface StopsModalProps {
  isOpen: boolean;
  onSubmit: (value: Array<Classifier<number>>) => void;
  onClose: () => void;
}
export interface StopsRow extends Classifier<number> {
  countryName: string | null;
  countyName: string | null | undefined;
  cityName: string | null;
  streetAddress: string | null | undefined;
}

export const StopsModal: FC<StopsModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<StopsRow[]>([]);

  const columns = useMemo<TableColumns<StopsRow>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
      {
        accessor: 'countryName',
        Header: <TransTableHead i18nKey="country" />,
      },
      {
        accessor: 'countyName',
        Header: <TransTableHead i18nKey="county" />,
      },
      {
        accessor: 'cityName',
        Header: <TransTableHead i18nKey="city" />,
      },
      {
        accessor: 'streetAddress',
        Header: <TransTableHead i18nKey="streetHouseNumber" />,
      },
    ],
    []
  );

  const table = useTable<StopsRow>(
    {
      data,
      columns,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const handleSearchSubmit = useCallback(async (filter) => {
    setIsLoading(true);
    const values = (await fetchStops(filter)).items;
    const mappedValues: StopsRow[] = values.map((stop) => ({
      id: stop.id,
      name: stop.name,
      countryName: stop.address.countryName,
      countyName: stop.address.countyName,
      cityName: stop.address.cityName,
      streetAddress: stop.streetAddress,
    }));
    setData(mappedValues);

    setIsLoading(false);
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={<TransTitle i18nKey="selectStops" />}
      actionButton={
        <Button
          variant="contained"
          type="submit"
          onClick={() => {
            onSubmit(
              table.selectedFlatRows.map((row) => ({
                name: row.original.name,
                id: row.original.id,
              }))
            );
          }}
          startIcon={<Icon name="check" size={20} />}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
      maxWidth="md"
      fullWidth
    >
      <Loadable loading={isLoading}>
        <Table
          caption={
            <>
              <StopSearchForm onFilterSubmit={handleSearchSubmit} />
              <Divider sx={{ pt: '24px' }} />
              <CardContent sx={{ pl: '0' }}>
                <Stack
                  direction="row"
                  alignItems="baseline"
                  justifyContent={'space-between'}
                  spacing={1}
                >
                  <Box>
                    <Typography variant="subtitle" fontWeight={700}>
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </>
          }
          table={table}
        />
      </Loadable>
    </Modal>
  );
};
