import type { FC } from 'react';
import { CardHeader, Layout, Loadable } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { PassengersTable } from 'routes/Passengers/PassengersTable';
import { useSelector } from 'store/utils';
import { passengersLoadingSelector } from 'features/loading/loadingSelectors';

interface PassengersListProps {}

export const PassengersList: FC<PassengersListProps> = () => {
  const loading = useSelector(passengersLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="passengersAllocation" />} />
        }
      >
        <PassengersTable />
      </Layout>
    </Loadable>
  );
};
