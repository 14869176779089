import React, { FC } from 'react';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Stack, Typography } from '@mui/material';
import { SmsTabs } from './SmsTabs';
import { MediumDataSms } from 'dto/mediums';
import { mergeRequiredLanguagesWithSmsTemplateData } from 'helpers/mediums';
import { useSelector } from 'store/utils';
import { notificationLanguagesSelector } from 'features/notifications/notificationsSelectors';
import { Highlight } from 'components/Highlight';
interface SmsProps {
  hasRecipients: boolean;
  templateSmsData: Array<MediumDataSms>;
}

export const Sms: FC<SmsProps> = ({ hasRecipients, templateSmsData }) => {
  const notificationLanguages = useSelector(notificationLanguagesSelector);

  const tabData = mergeRequiredLanguagesWithSmsTemplateData(
    templateSmsData,
    notificationLanguages || []
  );

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Typography variant="subtitle" fontWeight="700">
        <TransSubtitle i18nKey="sms" />
      </Typography>
      <Highlight>
        {hasRecipients ? (
          <SmsTabs tabData={tabData} />
        ) : (
          <Typography>
            <TransSubtitle i18nKey="noSmsReceivers" />
          </Typography>
        )}
      </Highlight>
    </Stack>
  );
};
