import { api } from '@fleet/shared';
import { GetLineNumbersResponse, GetServicesResponse } from 'dto/lines';
import { GetStopsResponse, GetStopsQueryParams } from 'dto/stop';
import qs from 'qs';

export const fetchStops = async (
  params: GetStopsQueryParams
): Promise<GetStopsResponse> =>
  (
    await api.get<GetStopsResponse>(
      `/stops${qs.stringify(params, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`,
      {
        baseURL: process.env.REACT_APP_API_LM,
      }
    )
  ).data;

export const fetchLineNumbers = async (carrierId: number) =>
  (
    await api.get<GetLineNumbersResponse>(`/organizations/${carrierId}/lines`, {
      baseURL: process.env.REACT_APP_API_LM,
    })
  ).data.items;

export const fetchServices = async (carrierId: number) =>
  (
    await api.get<GetServicesResponse>(
      `/service-codes${qs.stringify(
        {
          carrierId,
        },
        {
          addQueryPrefix: true,
          skipNulls: true,
        }
      )}`,
      {
        baseURL: process.env.REACT_APP_API_LM,
      }
    )
  ).data.items;
