import type { FC } from 'react';
import React from 'react';
import { Grid } from '@mui/material';
import { TextField, useFormContext } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';

interface SmsTemplateProps {
  index: number;
}

export const SmsTab: FC<SmsTemplateProps> = ({ index }) => {
  const form = useFormContext();

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    // FIXME: Re-used in all *Tabs components
    form.change(e.target.id, e.target.value);
  };

  return (
    <Grid container sx={{ alignItems: 'flex-start' }} spacing={2} columns={2}>
      <Grid item xs={1}>
        {/*<SelectField name="smsSender" options={data.senderOptions} /> TODO: Get these options from somewhere? */}
        <TextField
          name={`notificationsByLanguage[${index}].smsSenderName`}
          label={
            <>
              <TransField i18nKey="sendSmsAs" />
              {':'}
            </>
          }
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name={`notificationsByLanguage[${index}].smsSenderName`}
          disabled
          label={
            <>
              <TransField i18nKey="smsSenderName" />
              {':'}
            </>
          }
          onChange={handleFieldChange}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          multiline
          rows={5}
          name={`notificationsByLanguage[${index}].smsContent`}
          label={<TransField i18nKey="smsContent" />}
          onChange={handleFieldChange}
        />
      </Grid>
    </Grid>
  );
};
