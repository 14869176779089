import { createAsyncThunk } from 'store/utils';
import {
  BusinessEntity,
  ClassificationGroupItem,
  STANDARD_CLASSIFICATION_GROUPS,
} from 'dto/classification';
import { setCurrentBusinessEntity } from 'features/common/commonActions';
import qs from 'qs';
import { api } from '@fleet/shared';
import type { Classifier } from '@fleet/shared/dto/classifier';

export const getClassificationGroups = createAsyncThunk<
  Array<ClassificationGroupItem>
>(
  'getClassificationGroups',
  async () =>
    (
      await api.get<{ items: Array<ClassificationGroupItem> }>(
        `/classifications/groups${qs.stringify(
          {
            ids: Object.values(STANDARD_CLASSIFICATION_GROUPS),
          },
          {
            addQueryPrefix: true,
            skipNulls: true,
            arrayFormat: 'comma',
          }
        )}`,
        {
          baseURL: process.env.REACT_APP_API_BD,
        }
      )
    ).data.items
);

export const getBusinessEntities = createAsyncThunk<Array<BusinessEntity>>(
  'classification/getBusinessEntities',
  async (_, { getState, dispatch }) => {
    const {
      data: { items: businessEntities },
    } = await api.get<{ items: Array<BusinessEntity> }>(
      '/users/business-entities',
      {
        baseURL: process.env.REACT_APP_API_OM,
      }
    );

    const defaultBusinessEntity =
      businessEntities.find(({ isMain }) => isMain) || businessEntities[0];
    dispatch(
      setCurrentBusinessEntity(
        getState().common.currentBusinessEntityId || defaultBusinessEntity.id
      )
    );

    return businessEntities;
  }
);

export const getCarriers = createAsyncThunk<Array<Classifier<number>>>(
  'classification/getCarriers',
  async () =>
    (
      await api.get<{ organizations: Array<Classifier<number>> }>(
        `/organizations?${qs.stringify({
          RoleId: 'CONTACT_ROLE.BUS_COMPANY', // legacy for CARRIER,
        })}`,
        {
          baseURL: process.env.REACT_APP_API_OM,
        }
      )
    ).data.organizations
);

export const getCountries = createAsyncThunk<Array<Classifier<number>>>(
  'classification/getCountries',
  async () =>
    (
      await api.get<{ items: Array<Classifier<number>> }>('/countries', {
        baseURL: process.env.REACT_APP_API_BD,
      })
    ).data.items
);

export const getClassifications = createAsyncThunk(
  'getClassifications',
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(getClassificationGroups()),
      dispatch(getCarriers()),
      dispatch(getCountries()),
    ]);
  }
);
