import { createLoadingSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import { getPassengersByTrips } from 'features/passengers/passengersActions';
import {
  startReAccommodateCurrentProcess,
  getReAccommodateNewDetailedTrip,
  getReAccommodateNewTripsList,
  validateReAccommodateCurrentProcess,
} from 'features/reAccommodate/reAccommodateActions';

export const appLoadingSelector = createLoadingSelector(
  getClassifications,
  getBusinessEntities
);

export const passengersLoadingSelector =
  createLoadingSelector(getPassengersByTrips);

export const reAccommodateLoadingSelector = createLoadingSelector(
  getReAccommodateNewTripsList,
  getReAccommodateNewDetailedTrip,
  startReAccommodateCurrentProcess,
  validateReAccommodateCurrentProcess
);
