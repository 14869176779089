import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransField = createTrans({
  active: <Trans i18nKey="field.active" defaults="Active" />,
  carriageName: (
    <Trans i18nKey="field.carriageName" defaults="Carriage name/code" />
  ),
  carrier: <Trans i18nKey="field.carrier" defaults="Carrier" />,
  city: <Trans i18nKey="field.city" defaults="City" />,
  compositionName: (
    <Trans i18nKey="field.compositionName" defaults="Composition name/code" />
  ),
  country: <Trans i18nKey="field.country" defaults="Country" />,
  county: <Trans i18nKey="field.county" defaults="County" />,
  departureDateTime: (
    <Trans i18nKey="field.departureDateTime" defaults="Departure datetime" />
  ),
  departureDays: (
    <Trans i18nKey="field.departureDays" defaults="Departure days" />
  ),
  departureFrom: (
    <Trans i18nKey="field.departureFrom" defaults="Departure from" />
  ),
  departurePeriod: (
    <Trans
      i18nKey="field.departurePeriod"
      defaults="Departure date (from-to)"
    />
  ),
  departurePeriodFrom: (
    <Trans i18nKey="field.departurePeriodFrom" defaults="Departure from" />
  ),
  departurePeriodTo: (
    <Trans i18nKey="field.departurePeriodTo" defaults="Departure to" />
  ),
  departureTo: <Trans i18nKey="field.departureTo" defaults="Departure to" />,
  destinationStop: (
    <Trans i18nKey="field.destinationStop" defaults="Destination stop" />
  ),
  emailContent: <Trans i18nKey="field.emailContent" defaults="Email content" />,
  emailSubject: <Trans i18nKey="field.emailSubject" defaults="Email subject" />,
  languages: <Trans i18nKey="field.languages" defaults="Languages" />,
  lineName: <Trans i18nKey="field.lineName" defaults="Line name" />,
  lineNames: <Trans i18nKey="field.lineNames" defaults="Line names" />,
  lineNumber: <Trans i18nKey="field.lineNumber" defaults="Line number" />,
  lineNumbers: <Trans i18nKey="field.lineNumbers" defaults="Line numbers" />,
  name: <Trans i18nKey="field.name" defaults="Name" />,
  noStops: <Trans i18nKey="field.noStops" defaults="No stops" />,
  originStop: <Trans i18nKey="field.originStop" defaults="Origin stop" />,
  pushNotificationContent: (
    <Trans
      i18nKey="field.pushNotificationContent"
      defaults="Push notification content"
    />
  ),
  pushNotificationTitle: (
    <Trans
      i18nKey="field.pushNotificationTitle"
      defaults="Push notification title"
    />
  ),
  sendSmsAs: <Trans i18nKey="field.sendSmsAs" defaults="Send SMS as" />,
  senderEmail: <Trans i18nKey="field.senderEmail" defaults="Sender email" />,
  senderName: <Trans i18nKey="field.senderName" defaults="Sender name" />,
  serviceCode: <Trans i18nKey="field.serviceCode" defaults="Service code" />,
  serviceCodes: <Trans i18nKey="field.serviceCodes" defaults="Service codes" />,
  smsContent: <Trans i18nKey="field.smsContent" defaults="SMS content" />,
  smsSenderName: <Trans i18nKey="field.smsSenderName" defaults="Sender name" />,
  stopOne: <Trans i18nKey="field.stopOne" defaults="Stop one" />,
  stopTwo: <Trans i18nKey="field.stopTwo" defaults="Stop two" />,
  streetHouseNumber: (
    <Trans i18nKey="field.streetHouseNumber" defaults="Street, house, number" />
  ),
  tripName: <Trans i18nKey="field.tripName" defaults="Trip name" />,
  tripStatus: <Trans i18nKey="field.tripStatus" defaults="Trip status" />,
});
