import {
  DateField,
  FormProvider,
  SearchForm,
  SelectField,
  TextField,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { Grid } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import { ReAccommodateTripsFilter } from 'dto/reAccommodate';
import { setPassengersFilterByTrips } from 'features/passengers/passengersActions';
import { getReAccommodateNewTripsList } from 'features/reAccommodate/reAccommodateActions';
import { reAccommodateNewTripsFilterSelector } from 'features/reAccommodate/reAccommodateSelectors';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { useTripOptionsSearch } from 'hooks/useTripOptionsSearch';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useMemo } from 'react';
import { PassengersSearchControl } from 'routes/Passengers/Search/PassengersSearchControl';
import { PassengersStopField } from 'components/StopField/StopField';
import { useDispatch, useSelector } from 'store/utils';

interface ReAccommodateSelectTripSearchProps {}

export const ReAccommodateSelectTripSearch: FC<ReAccommodateSelectTripSearchProps> =
  () => {
    const dispatch = useDispatch();
    const filter = useSelector(reAccommodateNewTripsFilterSelector);

    const carrierOptions = useClassificationOptions(
      ClassificationGroup.CARRIER
    );
    const carrierIdFormInitValue = useCarrierInitValue(carrierOptions);

    const initialValues = useMemo<Partial<ReAccommodateTripsFilter>>(
      () => ({
        carrierId: carrierIdFormInitValue,
        ...filter,
      }),
      [carrierIdFormInitValue, filter]
    );

    const onSubmit = useCallback(
      async (filter: Partial<ReAccommodateTripsFilter>) =>
        formSubmit(async () => {
          (document.activeElement as HTMLInputElement)?.blur?.();
          await dispatch(
            getReAccommodateNewTripsList({ ...filter, offset: 0 })
          );
        }),
      [dispatch]
    );

    const { form, handleSubmit, values } = useForm({
      initialValues,
      onSubmit,
      subscription: { values: true },
    });

    const { carrierId } = values;

    const { lineNumberOptions, serviceOptions } =
      useTripOptionsSearch(carrierId);

    const handleReset = useCallback(() => {
      form.reset();
      dispatch(setPassengersFilterByTrips({}));
    }, [form, dispatch]);

    return (
      <SearchForm title={<TransSubtitle i18nKey="searchTrips" />}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <Grid container columns={5} spacing={2}>
              <Grid item xs={1}>
                <SelectField
                  name="carrierId"
                  label={<TransField i18nKey="carrier" />}
                  options={carrierOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="lineIds"
                  label={<TransField i18nKey="lineNames" />}
                  options={lineNumberOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="serviceCodes"
                  label={<TransField i18nKey="serviceCodes" />}
                  options={serviceOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="name"
                  label={<TransField i18nKey="tripName" />}
                />
              </Grid>
              <Grid item xs={1}>
                <DateField
                  name="departurePeriod"
                  label={<TransField i18nKey="departurePeriod" />}
                  selectsRange
                  isClearable
                />
              </Grid>
              <Grid item xs={1}>
                <PassengersStopField
                  name="stopOne"
                  label={<TransField i18nKey="originStop" />}
                />
              </Grid>
              <Grid item xs={1}>
                <PassengersStopField
                  name="stopTwo"
                  label={<TransField i18nKey="destinationStop" />}
                />
              </Grid>
              <PassengersSearchControl
                isDividerHidden
                handleReset={handleReset}
              />
            </Grid>
          </form>
        </FormProvider>
      </SearchForm>
    );
  };
