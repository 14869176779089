import { Button, Loadable, Modal, Table, TableColumns } from '@fleet/shared';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { CardContent, Divider, Radio, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { Row, usePagination, useTable } from 'react-table';
import { Pagination, PaginationParams } from '@fleet/shared/dto/pagination';
import { Icon } from '@fleet/shared/mui';
import { Classifier } from '@fleet/shared/dto/classifier';
import { StopsSearchParams, StopItem, GetStopsQueryParams } from 'dto/stop';
import { fetchStops } from 'features/passengers/passengersService';
import { StopSearchForm } from 'components/StopField/StopSearchForm';

interface StopSelectionModalProps {
  isOpen: boolean;
  onSubmit: (selectedStop: Classifier) => void;
  onClose: () => void;
}

export const StopSelectionModal: FC<StopSelectionModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const [data, setData] = useState<Pagination<StopItem>>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStop, setSelectedStop] = useState<Classifier>();
  const [paginationParams, setPaginationParams] = useState<PaginationParams>();
  const [filter, setFilter] = useState<StopsSearchParams>();

  const fetchData = useCallback(async (filter: GetStopsQueryParams) => {
    setIsLoading(true);
    const getStopsResponse = await fetchStops(filter);
    setData({
      ...getStopsResponse,
      items: getStopsResponse.items.map((stop) => ({
        id: stop.id,
        guid: stop.guid,
        name: stop.name,
        countryName: stop.address.countryName,
        countyName: stop.address.countyName,
        cityName: stop.address.cityName,
        streetAddress: stop.streetAddress,
      })),
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setSelectedStop(undefined);
      setData(undefined);
      setPaginationParams(undefined);
    }
  }, [isOpen]);

  const columns = useMemo<TableColumns<StopItem>>(
    () => [
      {
        id: 'selection',
        width: 40,
        Cell: ({ row }: { row: Row<StopItem> }) => (
          <Radio
            onClick={() =>
              setSelectedStop({
                id: row.original.guid,
                name: row.original.name,
              })
            }
            size="small"
            checked={row.original.guid === selectedStop?.id}
          />
        ),
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
      {
        accessor: 'countryName',
        Header: <TransTableHead i18nKey="country" />,
      },
      {
        accessor: 'countyName',
        Header: <TransTableHead i18nKey="county" />,
      },
      {
        accessor: 'cityName',
        Header: <TransTableHead i18nKey="city" />,
      },
      {
        accessor: 'streetAddress',
        Header: <TransTableHead i18nKey="streetHouseNumber" />,
      },
    ],
    [selectedStop]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      setPaginationParams(paginationParams);
      fetchData({ ...filter, ...paginationParams });
    },
    [fetchData, filter]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (data) {
        const { limit = pageSize, offset } = data;
        return offset / limit;
      }
      return 0;
    },
    [data]
  );

  const table = useTable<StopItem>(
    {
      data: useMemo(() => data?.items ?? [], [data?.items]),
      columns,
      pageCount: -1,
      total: data?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    usePagination
  );

  const handleSearchSubmit = useCallback(
    (filter) => {
      setFilter(filter);
      setSelectedStop(undefined);
      fetchData({ ...paginationParams, ...filter, offset: 0 });
    },
    [fetchData, paginationParams]
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={<TransTitle i18nKey="selectStop" />}
      actionButton={
        <Button
          variant="contained"
          type="submit"
          onClick={() => onSubmit(selectedStop!)}
          startIcon={<Icon name="check" size={20} />}
          disabled={!selectedStop}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
      maxWidth="md"
      fullWidth
    >
      <Loadable loading={isLoading}>
        <Table
          caption={
            <>
              <StopSearchForm onFilterSubmit={handleSearchSubmit} />
              <Divider sx={{ pt: '24px' }} />
              <CardContent sx={{ pl: '0' }}>
                <Stack direction="row" alignItems="baseline" spacing={1}>
                  <Typography variant="subtitle" fontWeight={700}>
                    <TransSubtitle i18nKey="searchResults" />
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <TransSubtitle
                      i18nKey="stopsQty"
                      values={{ count: data?.totalCount || 0 }}
                      tOptions={{ postProcess: 'interval' }}
                    />
                  </Typography>
                </Stack>
              </CardContent>
            </>
          }
          table={table}
        />
      </Loadable>
    </Modal>
  );
};
