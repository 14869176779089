import {
  FormProvider,
  PeriodField,
  RadioGroupField,
  SelectField,
  TextField,
  TimeUnit,
  ToggleButtonGroupField,
} from '@fleet/shared';
import { Grid } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import { PassengerFilterByTrips } from 'dto/passengers';
import { passengersFilterByTripSelector } from 'features/passengers/passengersSelectors';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { usePassengersSearchForm } from 'hooks/usePassengersSearchForm';
import { useWeekdaysOptions, weekDays } from 'hooks/useWeekdaysOptions';
import { TransField } from 'i18n/trans/field';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { PassengersStopField } from 'components/StopField/StopField';
import { PassengersSearchControl } from 'routes/Passengers/Search/PassengersSearchControl';
import { useDispatch, useSelector } from 'store/utils';
import { setPassengersFilterByTrips } from 'features/passengers/passengersActions';
import { useTripOptionsSearch } from 'hooks/useTripOptionsSearch';

interface PassengersSearchByTripFormProps {}

export const PassengersSearchByTripForm: FC<PassengersSearchByTripFormProps> =
  () => {
    const dispatch = useDispatch();
    const filter = useSelector(passengersFilterByTripSelector);

    const carrierOptions = useClassificationOptions(
      ClassificationGroup.CARRIER
    );
    const carrierIdFormInitValue = useCarrierInitValue(carrierOptions);
    const tripStatusOptions = useClassificationOptions(
      ClassificationGroup.TRIP_STATUS
    );
    const weekdaysOptions = useWeekdaysOptions();

    const initialValues = useMemo<Partial<PassengerFilterByTrips>>(
      () => ({
        carrierId: carrierIdFormInitValue,
        departureDays: [...weekDays],
        ...filter,
      }),
      [carrierIdFormInitValue, filter]
    );

    useEffect(() => {
      if (Object.keys(filter).length === 0) {
        dispatch(setPassengersFilterByTrips(initialValues));
      }
    }, [dispatch, filter, initialValues]);

    const { form, values, handleSubmit, handleReset } =
      usePassengersSearchForm(initialValues);

    const handleCarrierChange = useCallback(
      () =>
        form.batch(() => {
          form.change('lineIds');
          form.change('serviceCodes');
        }),
      [form]
    );

    const { carrierId } = values;

    const { lineNumberOptions, serviceOptions } =
      useTripOptionsSearch(carrierId);

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <SelectField
                name="carrierId"
                label={<TransField i18nKey="carrier" />}
                onChange={handleCarrierChange}
                options={carrierOptions}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="lineIds"
                label={<TransField i18nKey="lineNames" />}
                options={lineNumberOptions}
                multiple
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="serviceCodes"
                label={<TransField i18nKey="serviceCodes" />}
                options={serviceOptions}
                multiple
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="tripName"
                label={<TransField i18nKey="tripName" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="tripStatus"
                label={<TransField i18nKey="tripStatus" />}
                options={tripStatusOptions}
              />
            </Grid>
            <PeriodField
              from={{
                name: 'departureFrom',
                label: <TransField i18nKey="departureFrom" />,
                required: true,
              }}
              to={{
                name: 'departureTo',
                label: <TransField i18nKey="departureTo" />,
                required: true,
              }}
              maxRange={{ [TimeUnit.DAYS]: 60 }}
            />
            <Grid item xs={3}>
              <ToggleButtonGroupField
                name="departureDays"
                label={<TransField i18nKey="departureDays" />}
                options={weekdaysOptions}
                fullWidth
                multiple
              />
            </Grid>
            <Grid item xs={1}>
              <PassengersStopField
                name="originStop"
                label={<TransField i18nKey="originStop" />}
              />
            </Grid>
            <Grid item xs={1}>
              <PassengersStopField
                name="destinationStop"
                label={<TransField i18nKey="destinationStop" />}
              />
            </Grid>
            <Grid item xs={1}>
              <PassengersStopField
                name="stopOne"
                label={<TransField i18nKey="stopOne" />}
              />
            </Grid>
            <Grid item xs={1}>
              <PassengersStopField
                name="stopTwo"
                label={<TransField i18nKey="stopTwo" />}
              />
            </Grid>
            <Grid item xs={1}>
              <RadioGroupField
                name="isTripActive"
                label={<TransField i18nKey="active" />}
                options="BOOL"
                inline
              />
            </Grid>
            <PassengersSearchControl handleReset={handleReset} />
          </Grid>
        </form>
      </FormProvider>
    );
  };
