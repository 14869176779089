import { Nullable } from 'helpers/types';
import { Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared';
import React from 'react';

export const getSuccessOrFailureText = (error: Nullable<string>) => {
  return (
    <>
      {error ? (
        <Stack direction="row" sx={{ ml: 1 }}>
          <Icon name={'error-circle'} color="error" />
          <Typography color={'red'} sx={{ ml: '4px' }}>
            Error
          </Typography>
        </Stack>
      ) : (
        <Stack direction="row" sx={{ ml: 1 }}>
          <Icon name={'check-circle'} color="success" />
          <Typography color="green" sx={{ ml: '4px' }}>
            Success
          </Typography>
        </Stack>
      )}
    </>
  );
};
