import React, { FC, useEffect } from 'react';
import {
  Button,
  FormProvider,
  Icon,
  Layout,
  useForm,
  useModal,
} from '@fleet/shared';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UseTemplate } from './sections/useTemplate/UseTemplate';
import { NotificationChannels } from './sections/NotificationChannels';
import { NotificationLanguages } from './sections/NotificationLanguages';
import { useDispatch, useSelector } from 'store/utils';
import {
  getValidatedNotifications,
  sendCustomNotifications,
  setActiveStep,
  setMappedNotificationContentFormValues,
  setNotificationLanguagesData,
} from 'features/notifications/notificationsActions';
import { TransButton } from 'i18n/trans/button';
import {
  currentTemplateSelector,
  notificationAddresseesSelector,
  notificationContentFormValuesSelector,
  notificationLanguagesSelector,
} from 'features/notifications/notificationsSelectors';
import { AddresseeData } from 'routes/SendNotifications/NotificationAddressees';
import {
  NotificationContentFormValues,
  NotificationLanguage,
  RecipientsCountByLanguageId,
} from 'dto/notificationContent';
import { useClassificationMap } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { Mediums } from 'routes/SendNotifications/content/sections/Mediums';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { ValidationModal } from 'routes/SendNotifications/content/validation/ValidationModal';
import { Step } from 'dto/stepper';
import {
  PostSendCustomNotificationBody,
  PostValidateNotificationTemplateBody,
} from 'dto/api/notifications-management';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: 'none',
  },
}));
interface NotificationContentProps {}
const getRecipientsCountByLanguageId = (
  addressees: Array<AddresseeData>
): RecipientsCountByLanguageId => {
  const countMapByLanguage: RecipientsCountByLanguageId = {};

  addressees.forEach((recipient) => {
    const { languageId } = recipient;

    if (!countMapByLanguage[languageId]) {
      countMapByLanguage[languageId] = {
        emailRecipients: 0,
        smsRecipients: 0,
        pushRecipients: 0,
      };
    }

    if (recipient.email) {
      countMapByLanguage[languageId].emailRecipients++;
    }

    if (recipient.phoneNumber) {
      countMapByLanguage[languageId].smsRecipients++;
    }

    if (recipient.customerProfileId) {
      countMapByLanguage[languageId].pushRecipients++;
    }
  });
  return countMapByLanguage;
};

const createNotificationLanguagesData = (
  recipients: RecipientsCountByLanguageId,
  languages: Map<string, string>
): Array<NotificationLanguage> =>
  Object.keys(recipients).map((languageId) => ({
    languageId,
    language: languages.get(languageId)!,
    useFallbackLanguage: false,
    ...recipients[languageId],
  }));

export const NotificationContent: FC<NotificationContentProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const addressees = useSelector(notificationAddresseesSelector);
  const addresseeLanguages = useSelector(notificationLanguagesSelector);
  const currentTemplate = useSelector(currentTemplateSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const formValues = useSelector(notificationContentFormValuesSelector);
  const languagesMap = useClassificationMap(ClassificationGroup.CULTURE);
  const { open: isOpen, onOpen, onClose } = useModal();

  useEffect(() => {
    dispatch(
      setNotificationLanguagesData(
        createNotificationLanguagesData(
          getRecipientsCountByLanguageId(addressees),
          languagesMap
        )
      )
    );
  }, [dispatch, addressees, languagesMap]);
  const validateNotifications = async (
    payload: PostValidateNotificationTemplateBody
  ) => {
    await dispatch(getValidatedNotifications(payload));
  };
  const onSubmit = (values: NotificationContentFormValues) => {
    delete values.sendNotificationTo; // currently not used anywhere

    const payload: PostValidateNotificationTemplateBody = {
      typeId: currentTemplate?.type?.id || 'NOTIFICATION_TEMPLATE_TYPE.CUSTOM',
      notificationsByLanguage: values.notificationsByLanguage.map(
        (notification) => ({
          ...notification,
          languageId: notification.language.id,
        })
      ),
    };

    dispatch(
      setMappedNotificationContentFormValues({
        notificationsByLanguage: payload.notificationsByLanguage,
        fallbackLanguageId: values.fallbackLanguageId,
      })
    );

    validateNotifications(payload);
  };

  const { form, handleSubmit } = useForm({ onSubmit });

  const onValidationModalSubmit = async () => {
    // TODO: Collect data and merge them into sendcustomNotification endpoint payload
    if (!formValues) {
      return;
    }

    const payload: PostSendCustomNotificationBody = {
      organizationId: currentBusinessEntityId,
      notificationTemplateId: currentTemplate?.id, // actually not required because contents array is always filled and BE prioritises that over this
      contents: formValues.notificationsByLanguage.map((notification) => ({
        ...notification,
        isDefault: Boolean(
          notification.languageId === formValues.fallbackLanguageId
        ),
      })),
      customers: addressees,
    };

    await dispatch(sendCustomNotifications(payload));

    onClose();
    // TODO: Shouldnt direct to summary if sendCustomNotifications fails
    dispatch(setActiveStep(Step.Summary));
  };
  return (
    <>
      <Layout classes={classes}>
        <>
          <UseTemplate />
          <Divider />

          <FormProvider {...form}>
            <form onSubmit={handleSubmit}>
              <NotificationChannels />
              <Divider />
              <NotificationLanguages />
              <Divider />
              <Mediums />
              <Stack direction="row" sx={{ py: 2, px: 3 }}>
                <Stack direction="row" sx={{ my: 'auto' }}>
                  <Typography fontWeight={400}>
                    <TransSubtitle
                      i18nKey="selectedAddressees"
                      values={{ count: addressees.length }}
                    />
                    ,{' '}
                    <TransSubtitle
                      i18nKey="selectedLanguages"
                      values={{ count: addresseeLanguages?.length ?? 0 }}
                    />
                  </Typography>
                  <Icon name="info-circle" sx={{ ml: 1, my: 'auto' }} />
                </Stack>
                <Box sx={{ flex: 1 }}></Box>
                <Stack direction="row">
                  <Button
                    onClick={() => {
                      console.log('cancel');
                    }}
                    variant="text"
                  >
                    <TransButton i18nKey="cancel" />
                  </Button>
                  <Button
                    onClick={() => {
                      form.reset();
                    }}
                    variant="text"
                  >
                    <TransButton i18nKey="reset" />
                  </Button>
                  <Button
                    icon="search"
                    onClick={(e) => {
                      handleSubmit();
                      onOpen(e);
                    }}
                    sx={{ ml: 2 }}
                  >
                    <TransButton i18nKey="validate" />
                  </Button>
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </>
      </Layout>
      <ValidationModal
        isOpen={isOpen}
        onSubmit={onValidationModalSubmit}
        onClose={onClose}
      />
    </>
  );
};
