import { ArrayElement } from '@fleet/shared/@types/openapi-typescript';
import type { Classifier } from '@fleet/shared/dto/classifier';
import {
  DeviationsRequest,
  DeviationsResponse,
} from 'docs';

export enum PassengerSearchGroup {
  TRIPS = 'TRIPS',
}

export type PostPassengersByTripsRequest = DeviationsRequest<
  '/trips/admissions/search',
  'post'
>;
export type PostPassengersByTripsResponse = DeviationsResponse<
  '/trips/admissions/search',
  'post'
>;
export type Passenger = ArrayElement<PostPassengersByTripsResponse['items']>;

export interface PassengerFilterByTrips
  extends Omit<
    PostPassengersByTripsRequest,
    'originStopId' | 'destinationStopId' | 'stopOneId' | 'stopTwoId'
  > {
  originStop: Classifier;
  destinationStop: Classifier;
  stopOne: Classifier;
  stopTwo: Classifier;
}
