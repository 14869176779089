import {
  Button,
  Icon,
  SearchResult,
  Table,
  TableColumns,
  currentDateTimeFormat,
  formatDate,
  useRowActive,
  useRowExpandToggle,
  useRowSelectRadio,
  useTable,
} from '@fleet/shared';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { CardContent, Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReAccommodateTrip } from 'dto/reAccommodate';
import { reAccommodateLoadingSelector } from 'features/loading/loadingSelectors';
import {
  getReAccommodateNewTripsList,
  setReAccommodateActiveStep,
} from 'features/reAccommodate/reAccommodateActions';
import {
  reAccommodateNewTripsListSelector,
  reAccommodateNewTripsFilterSelector,
} from 'features/reAccommodate/reAccommodateSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useMemo } from 'react';
import { useExpanded, usePagination, useRowSelect } from 'react-table';
import { ReAccommodateStepProps } from 'routes/Passengers/ReAccommodate/ReAccommodateSteps';
import { ReAccommodateSelectTripSearch } from 'routes/Passengers/ReAccommodate/SelectTrip/ReAccommodateSelectTripSearch';
import { ReAccommodateSelectTripSubTable } from 'routes/Passengers/ReAccommodate/SelectTrip/ReAccommodateSelectTripSubTable';
import { useDispatch, useSelector } from 'store/utils';

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(0, 2, 2, 2),
    '& .MuiButton-text': { padding: 0 },
  },
}));

export const ReAccommodateSelectTrip: FC<ReAccommodateStepProps> = ({
  onCancelReAccommodate,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const trips = useSelector(reAccommodateNewTripsListSelector);
  const filter = useSelector(reAccommodateNewTripsFilterSelector);
  const loading = useSelector(reAccommodateLoadingSelector);
  const data = useMemo(() => trips?.items ?? [], [trips]);

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      await dispatch(
        getReAccommodateNewTripsList({ ...filter, ...paginationParams })
      ).unwrap();
    },
    [dispatch, filter]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (trips) {
        const { offset } = trips;
        return offset / pageSize;
      }

      return 0;
    },
    [trips]
  );

  const columns = useMemo<TableColumns<ReAccommodateTrip>>(
    () => [
      {
        accessor: 'serviceCode',
        Cell: ({ value }) => value ?? ' - ',
        Header: <TransTableHead i18nKey="serviceCode" />,
      },
      {
        accessor: 'departureDateTime',
        Cell: ({ value }) => formatDate(value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="departureTime" />,
      },
      {
        accessor: 'carrier',
        Cell: ({ value }) => value?.name ?? '-',
        Header: <TransTableHead i18nKey="carrier" />,
      },
      {
        accessor: 'lineName',
        Cell: ({ value }) => value ?? ' - ',
        Header: <TransTableHead i18nKey="lineName" />,
      },
      {
        accessor: 'name',
        Cell: ({ value }) => value ?? ' - ',
        Header: <TransTableHead i18nKey="tripName" />,
      },
      // {
      //   accessor: 'originStop',
      //   Header: <TransTableHead i18nKey="originStop" />,
      // },
      // {
      //   accessor: 'destinationStop',
      //   Header: <TransTableHead i18nKey="destinationStop" />,
      // },
      {
        accessor: 'vehicleCompositions',
        Cell: ({ value }) => (value ?? []).map(({ name }) => name).join(' + '),
        Header: <TransTableHead i18nKey="vehicleProfile" />,
      },
    ],
    []
  );

  const table = useTable<ReAccommodateTrip>(
    {
      data,
      columns,
      pageCount: -1,
      total: trips?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    useRowActive,
    useExpanded,
    usePagination,
    useRowSelect,
    useRowExpandToggle,
    useRowSelectRadio
  );

  const { selectedFlatRows } = table;
  const tripId = useMemo(
    () => selectedFlatRows[0]?.original.id,
    [selectedFlatRows]
  );

  const handleNextStepClick = useCallback(async () => {
    if (selectedFlatRows.length !== 1) {
      return;
    }

    // await dispatch(
    //   startReAccommodateCurrentProcess({ newTripId: tripId })
    // ).unwrap();

    // await dispatch(
    //   validateReAccommodateCurrentProcess({ newTripId: tripId })
    // ).unwrap();

    dispatch(setReAccommodateActiveStep('confirmChanges'));
  }, [dispatch, selectedFlatRows.length]);

  return (
    <>
      <ReAccommodateSelectTripSearch />
      <Divider />
      <SearchResult results={!!data.length} loading={loading}>
        <Table
          caption={
            <CardContent>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="subtitle" fontWeight="700">
                  <TransSubtitle i18nKey="searchResults" />
                </Typography>
                {Boolean(trips?.totalCount) && (
                  <Typography variant="body2" color="text.secondary">
                    <TransSubtitle
                      i18nKey="tripsQty"
                      values={{
                        count: trips ? trips.totalCount : 0,
                      }}
                      tOptions={{ postProcess: 'interval' }}
                    />
                  </Typography>
                )}
              </Stack>
            </CardContent>
          }
          table={table}
          getTableProps={{ sx: { tableLayout: 'fixed' } }}
          getSubRow={({ original: { id } }) => (
            <CardContent>
              <ReAccommodateSelectTripSubTable tripId={id} />
            </CardContent>
          )}
          getRowProps={() => ({
            sx: { backgroundColor: 'common.white' },
            onClick: () => null,
          })}
        />
      </SearchResult>
      <Stack
        direction="row"
        justifyContent="flex-end"
        className={classes.actions}
        spacing={2}
      >
        <>
          <Button variant="text" onClick={onCancelReAccommodate}>
            <TransButton i18nKey="cancel" />
          </Button>
          <Button
            startIcon={<Icon name="arrow-right" />}
            disabled={!tripId}
            onClick={handleNextStepClick}
          >
            <TransButton i18nKey="reAccommodate" />
          </Button>
        </>
      </Stack>
    </>
  );
};
