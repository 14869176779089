import type { FC } from 'react';
import { Route, Switch } from 'react-router';
import { PassengersList } from 'routes/Passengers/PassengersList';
import { ReAccommodate } from 'routes/Passengers/ReAccommodate/ReAccommodate';

interface PassengersProps {}

export const Passengers: FC<PassengersProps> = () => {
  return (
    <Switch>
      <Route path="/passengers-allocation" component={PassengersList} exact />
      <Route
        path="/passengers-allocation/re-accommodate"
        component={ReAccommodate}
        exact
      />
    </Switch>
  );
};
