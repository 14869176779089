import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  departureTimesOrTripsFilled: (
    <Trans
      i18nKey="alert.departureTimesOrTripsFilled"
      defaults="Either departure times or trips need to be filled"
    />
  ),
  fillAtLeastOne: (
    <Trans
      i18nKey="alert.fillAtLeastOne"
      defaults="At least one filter must be filled"
    />
  ),
});
