import React, { FC } from 'react';
import { Email } from 'routes/SendNotifications/content/sections/email/Email';
import { Divider } from '@mui/material';
import { Sms } from 'routes/SendNotifications/content/sections/sms/Sms';
import { useSelector } from 'store/utils';
import {
  currentTemplateSelector,
  notificationLanguagesSelector,
} from 'features/notifications/notificationsSelectors';
import {
  countTotalRecipients,
  getMediumDataByLanguageByMedium,
} from 'helpers/mediums';

interface MediumsProps {}
// TODO: If medium channel tabs are rendered with incomplete data, we show error icon,
// we need to update it to success icon after necessary content added.

export const Mediums: FC<MediumsProps> = () => {
  const currentTemplate = useSelector(currentTemplateSelector);
  const notificationLanguages = useSelector(notificationLanguagesSelector);
  const { email, sms } = getMediumDataByLanguageByMedium(currentTemplate);
  const recipientCounts = countTotalRecipients(notificationLanguages);

  return (
    <>
      <Email
        hasRecipients={Boolean(recipientCounts.totalEmailRecipients)}
        templateEmailData={email}
      />
      <Divider sx={{ my: 2 }} />
      <Sms
        hasRecipients={Boolean(recipientCounts.totalSmsRecipients)}
        templateSmsData={sms}
      />
      <Divider sx={{ my: 2 }} />
      {/*<Push*/}
      {/*  hasRecipients={Boolean(recipientCounts.totalPushRecipients)}*/}
      {/*  templatePushData={push}*/}
      {/*/>*/}
    </>
  );
};
