import { Period } from '@fleet/shared';
import { ArrayElement } from '@fleet/shared/@types/openapi-typescript';
import { Classifier } from '@fleet/shared/dto/classifier';
import {
  DeviationsRequest,
  DeviationsResponse,
  LineNetworkRequest,
  LineNetworkResponse,
} from 'docs';

export const passengerReAccommodateStep = {
  selectTrip: 0,
  confirmChanges: 1,
} as const;

export type PassengerReAccommodateStep =
  keyof typeof passengerReAccommodateStep;

export type PostReAccommodateTripsRequest = LineNetworkRequest<
  '/organizations/{organizationId}/trips/get',
  'post'
>;
export type PostReAccommodateTripsResponse = LineNetworkResponse<
  '/organizations/{organizationId}/trips/get',
  'post'
>;
export type ReAccommodateTrip = ArrayElement<
  PostReAccommodateTripsResponse['items']
>;

export type GetReAccommodateDetailedTripResponse = LineNetworkResponse<
  '/trips/{tripId}/route-details',
  'get'
>;
export type ReAccommodateDetailedTripStop = ArrayElement<
  NonNullable<GetReAccommodateDetailedTripResponse['routeStops']>
>;

export interface ReAccommodateTripsFilter
  extends Omit<
    PostReAccommodateTripsRequest,
    'stopOneId' | 'stopTwoId' | 'tripDateFrom' | 'tripDateTo'
  > {
  carrierId: number;
  departurePeriod: Period;
  stopOne: Classifier;
  stopTwo: Classifier;
}

export interface PostReAccommodateRequestNewTrip {
  newTripId: number;
  newOriginRouteId?: number | null;
  newDestinationRouteId?: number | null;
}

export type PostReAccommodateStartRequest = DeviationsRequest<
  '/re-allocations/start',
  'post'
>;
export type PostReAccommodateStartResponse = DeviationsResponse<
  '/re-allocations/start',
  'post'
>;

export type PostReAccommodateValidateRequest = DeviationsRequest<
  '/re-allocations/validate',
  'post'
>;
export type PostReAccommodateValidateResponse = DeviationsResponse<
  '/re-allocations/validate',
  'post'
>;
