import React, { FC, useEffect } from 'react';
import { Icon, TabPanel, Tabs, useFormContext } from '@fleet/shared';
import { Typography } from '@mui/material';
import { SmsTab } from 'routes/SendNotifications/content/sections/sms/SmsTab';
import { EnrichedMediumDataSms } from 'dto/mediums';
import { notificationLanguagesSelector } from 'features/notifications/notificationsSelectors';
import { useSelector } from 'store/utils';

interface SmsTabsProps {
  tabData: Array<EnrichedMediumDataSms>;
}

export const SmsTabs: FC<SmsTabsProps> = ({ tabData }) => {
  const form = useFormContext();
  const notificationLanguages = useSelector(notificationLanguagesSelector);
  const hasNecessaryContent = (tab: EnrichedMediumDataSms) =>
    tab.smsContent && tab.smsSenderName;

  const hasRecipients = (tab: EnrichedMediumDataSms): boolean => {
    return Boolean(
      notificationLanguages?.find((lang) => lang.languageId === tab.language.id)
        ?.smsRecipients
    );
  };

  const doesntUseChosenFallbackLanguage = (data: EnrichedMediumDataSms) =>
    !data.useFallbackLanguage;

  useEffect(() => {
    tabData
      .filter(hasRecipients)
      .filter(doesntUseChosenFallbackLanguage)
      .forEach((tab, i) => {
        Object.keys(tab).forEach((key) => {
          form.change(
            `notificationsByLanguage[${i}].${key}`,
            tab[key as keyof EnrichedMediumDataSms]
          );
        });
      });
  });

  return (
    <Tabs value={tabData[0].language.id}>
      {tabData
        .filter(doesntUseChosenFallbackLanguage)
        .filter(hasRecipients)
        .map((tab, i) => {
          return (
            <TabPanel
              value={tab.language.id}
              key={tab.language.id}
              label={
                <Typography>
                  {tab.language.name}
                  &nbsp;
                  {hasNecessaryContent(tab) ? (
                    <Icon name={'check-circle'} color="success" />
                  ) : (
                    <Icon name={'error-circle'} color="error" />
                  )}
                </Typography>
              }
            >
              <SmsTab index={i} />
            </TabPanel>
          );
        })}
    </Tabs>
  );
};
