import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransButton = createTrans({
  addEdit: <Trans i18nKey="button.addEdit" defaults="Add/edit" />,
  cancel: <Trans i18nKey="button.cancel" defaults="Cancel" />,
  carriages: <Trans i18nKey="button.carriages" defaults="Carriages" />,
  close: <Trans i18nKey="button.close" defaults="Close" />,
  confirm: <Trans i18nKey="button.confirm" defaults="Confirm" />,
  createMoreNotifications: (
    <Trans
      i18nKey="button.createMoreNotifications"
      defaults="Create more notifications"
    />
  ),
  fillFromTemplate: (
    <Trans i18nKey="button.fillFromTemplate" defaults="Fill from template" />
  ),
  help: <Trans i18nKey="button.help" defaults="Help" />,
  notificationAddressees: (
    <Trans
      i18nKey="button.notificationAddressees"
      defaults="Notification addressees"
    />
  ),
  notificationContent: (
    <Trans
      i18nKey="button.notificationContent"
      defaults="Notification content"
    />
  ),
  placeholderList: (
    <Trans i18nKey="button.placeholderList" defaults="Placeholder list" />
  ),
  reAccommodate: (
    <Trans i18nKey="button.reAccommodate" defaults="Re-accommodate" />
  ),
  reAccommodation: (
    <Trans i18nKey="button.reAccommodation" defaults="Re-accommodation" />
  ),
  reset: <Trans i18nKey="button.reset" defaults="Reset fields" />,
  resetFilters: (
    <Trans i18nKey="button.resetFilters" defaults="Reset filters" />
  ),
  search: <Trans i18nKey="button.search" defaults="Search" />,
  selectStop: <Trans i18nKey="button.selectStop" defaults="Select stop" />,
  sendNotifications: (
    <Trans i18nKey="button.sendNotifications" defaults="Send notifications" />
  ),
  validate: <Trans i18nKey="button.validate" defaults="Validate" />,
  viewLogs: <Trans i18nKey="button.viewLogs" defaults="View logs" />,
  viewNotificationHistory: (
    <Trans
      i18nKey="button.viewNotificationHistory"
      defaults="View notification history"
    />
  ),
  viewProcessLogs: (
    <Trans i18nKey="button.viewProcessLogs" defaults="View process logs" />
  ),
  viewUnresolvedPassengers: (
    <Trans
      i18nKey="button.viewUnresolvedPassengers"
      defaults="View unresolved passengers"
    />
  ),
});
