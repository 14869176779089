import { createSelector, selector } from 'store/utils';

export const selectActiveStep = selector(
  (state) => state.notifications.activeStep
);
export const activeStepSelector = createSelector(selectActiveStep);

export const selectNotificationChannels = selector(
  (state) => state.notifications.notificationContent.notificationChannels.list
);
export const notificationChannelsSelector = createSelector(
  selectNotificationChannels
);

export const selectNotificationLanguages = selector(
  (state) =>
    state.notifications.notificationContent?.notificationLanguages?.list
);
export const notificationLanguagesSelector = createSelector(
  selectNotificationLanguages
);

const selectNotificationFilterList = selector(
  (state) => state.notifications.notificationFilters.list
);
export const notificationFilterListSelector = createSelector(
  selectNotificationFilterList
);
const selectNotificationAddressees = selector(
  (state) => state.notifications.notificationAddressees.list
);
export const notificationAddresseesSelector = createSelector(
  selectNotificationAddressees
);

const selectNotificationTemplatesList = selector(
  (state) => state.notifications.notificationContent.templates.list
);
export const notificationTemplatesListSelector = createSelector(
  selectNotificationTemplatesList
);

const selectCurrentTemplate = selector(
  (state) => state.notifications.notificationContent.templates.current
);
export const currentTemplateSelector = createSelector(selectCurrentTemplate);

const selectValidatedNotifications = selector(
  (state) => state.notifications.notificationContent.validation.list
);

export const validatedNotificationsSelector = createSelector(
  selectValidatedNotifications
);

const selectNotificationSummary = selector(
  (state) => state.notifications.notificationSummary
);

export const notificationSummarySelector = createSelector(
  selectNotificationSummary
);

const selectNotificationContentFormValues = selector(
  (state) => state.notifications.notificationContent.validation.formValues
);
export const notificationContentFormValuesSelector = createSelector(
  selectNotificationContentFormValues
);
