import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransSubtitle = createTrans({
  ancillariesQty: (
    <Trans
      i18nKey="subtitle.ancillariesQty"
      defaults="(0)[0 ancillaries];(1)[{{count}} ancillary];(2-inf)[{{count}} ancillaries];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  assignees: <Trans i18nKey="subtitle.assignees" defaults="Assignees" />,
  confirmChanges: (
    <Trans i18nKey="subTitle.confirmChanges" defaults="Confirm changes" />
  ),
  email: <Trans i18nKey="subtitle.email" defaults="Email" />,
  emailsQty: (
    <Trans
      i18nKey="subtitle.emailsQty"
      defaults="(0)[{{count}} emails];(1)[{{count}} email];(2-inf)[{{count}} emails];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  inventoryClassesQty: (
    <Trans
      i18nKey="subtitle.inventoryClassesQty"
      defaults="(0)[0 inventory classes];(1)[{{count}} inventory class];(2-inf)[{{count}} inventory classes];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  linesQty: (
    <Trans
      i18nKey="subtitle.linesQty"
      defaults="(0)[{{count}} lines];(1)[{{count}} line];(2-inf)[{{count}} lines];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  noEmailReceivers: (
    <Trans
      i18nKey="subtitle.noEmailReceivers"
      defaults="No receivers for email notifications"
    />
  ),
  noPushReceivers: (
    <Trans
      i18nKey="subtitle.noPushReceivers"
      defaults="No receivers for push notifications"
    />
  ),
  noSmsReceivers: (
    <Trans
      i18nKey="subtitle.noSmsReceivers"
      defaults="No receivers for sms notifications"
    />
  ),
  notificationChannels: (
    <Trans
      i18nKey="subtitle.notificationChannels"
      defaults="Notification channels"
    />
  ),
  notificationLanguages: (
    <Trans
      i18nKey="subtitle.notificationLanguages"
      defaults="Notification languages"
    />
  ),
  passengersQty: (
    <Trans
      i18nKey="subTitle.passengersQty"
      defaults="(0)[no passengers];(1)[{{count}} passenger];(2-inf)[{{count}} passengers];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  phoneNumbersQty: (
    <Trans
      i18nKey="subtitle.phoneNumbersQty"
      defaults="(0)[{{count}} phone numbers];(1)[{{count}} phone number];(2-inf)[{{count}} phone numbers];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  potentialAddresseesQty: (
    <Trans
      i18nKey="subtitle.potentialAddresseesQty"
      defaults="(0)[{{count}} potential addressees];(1)[{{count}} potential addressee];(2-inf)[{{count}} potential addressees];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  push: <Trans i18nKey="subtitle.push" defaults="Push notification" />,
  searchResults: (
    <Trans i18nKey="subTitle.searchResults" defaults="Search results" />
  ),
  searchTrips: <Trans i18nKey="subTitle.searchTrips" defaults="Search trips" />,
  selectTrip: <Trans i18nKey="subTitle.selectTrip" defaults="Select trip" />,
  selectedAddressees: (
    <Trans
      i18nKey="subtitle.selectedAddressees"
      defaults="(0)[{{count}} selected addressees];(1)[{{count}} selected addressee];(2-inf)[{{count}} selected addressees];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  selectedLanguages: (
    <Trans
      i18nKey="subtitle.selectedLanguages"
      defaults="(0)[{{count}} languages];(1)[{{count}} language];(2-inf)[{{count}} languages];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  selectedQty: (
    <Trans i18nKey="subtitle.selectedQty" defaults="{{count}} selected" />
  ),
  sms: <Trans i18nKey="subtitle.sms" defaults="SMS" />,
  stopsQty: (
    <Trans
      i18nKey="subtitle.stopsQty"
      defaults="(0)[no stops];(1)[{{count}} stop];(2-inf)[{{count}} stops];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  tripsQty: (
    <Trans
      i18nKey="subtitle.tripsQty"
      defaults="(0)[no trips];(1)[{{count}} trip];(2-inf)[{{count}} trips];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  useTemplate: <Trans i18nKey="subtitle.useTemplate" defaults="Use template" />,
});
