import { formSubmit, useForm } from '@fleet/shared';
import { PassengerSearchGroup } from 'dto/passengers';
import {
  getPassengersByTrips,
  setPassengersFilterByTrips,
} from 'features/passengers/passengersActions';
import {
  passengersSelectorByTrip,
  passengersTabSelector,
  selectPassengersFilterByTrip,
} from 'features/passengers/passengersSelectors';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'store/utils';

export const passengersSearchCallbacks = {
  [PassengerSearchGroup.TRIPS]: {
    passengersSelector: passengersSelectorByTrip,
    getPassengers: getPassengersByTrips,
    setPassengersFilter: setPassengersFilterByTrips,
    selectPassengersFilter: selectPassengersFilterByTrip,
  },
};

export const usePassengersSearchForm = <T extends {}>(
  initialValues: Partial<T>
) => {
  const dispatch = useDispatch();
  const tab = useSelector(passengersTabSelector);
  const { getPassengers, setPassengersFilter } = passengersSearchCallbacks[tab];

  const onSubmit = useCallback(
    async (filter: T) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getPassengers({ ...filter, offset: 0 }));
      }),
    [dispatch, getPassengers]
  );

  const { form, handleSubmit, values } = useForm({
    initialValues,
    onSubmit,
    subscription: { values: true },
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setPassengersFilter({}));
  }, [form, dispatch, setPassengersFilter]);

  return { form, values, handleSubmit, handleReset };
};
