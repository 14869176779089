import React, { ReactNode, useCallback, useState } from 'react';
import {
  FormControl,
  FormField,
  FormFieldProps,
  Input,
  useFormContext,
  useModal,
} from '@fleet/shared';
import {
  FinalFieldAdapterProps,
  metaError,
} from '@fleet/shared/form/FormField';
import { Box, Link, Stack, Typography } from '@mui/material';
import { LinesModal } from './Modals/LinesModal';
import { noop } from 'lodash';
import { TransButton } from 'i18n/trans/button';
import { TripsModal } from './Modals/TripsModal';
import { TransLabel } from '@fleet/shared/i18n/trans/label';
import { StopsModal } from './Modals/StopsModal';
import { InventoryClassesModal } from './Modals/InventoryClassesModal';
import { AncillariesModal } from './Modals/AncillariesModal';
import type { Classifier } from '@fleet/shared/dto/classifier';

interface TicketTextsStopFieldProps {
  label: ReactNode;
}
interface FareModelStopsSelectAdapterProps<TValue extends Classifier<number>>
  extends FinalFieldAdapterProps<TValue, HTMLDivElement>,
    Partial<TicketTextsStopFieldProps> {}

function TextfieldWithSearchAdapter<TValue extends Classifier<number>>({
  label,
  input,
  meta,
}: FareModelStopsSelectAdapterProps<TValue>) {
  const { name } = input;
  const value = input.value as unknown as string;
  const [data, setData] = useState<string>(value || '');
  const { open: isOpen, onOpen, onClose } = useModal();
  const form = useFormContext();

  const handleSubmit = useCallback(
    (values: Array<Classifier<number | string>>) => {
      form.change(
        name,
        values.map((v) => v.id)
      );
      setData(values.map((v) => v.name).join(', '));
      onClose();
    },
    [onClose, form, name]
  );

  return (
    <>
      <FormControl label={label} error={metaError(meta)}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Input
            name={name}
            value={data.split(',')[0]}
            onChange={noop}
            disabled
            required={false}
            helper={
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                component="span"
              >
                {data.split(',').length > 1 ? (
                  <Typography variant="body2" component="span">
                    <TransLabel
                      i18nKey="plusMore"
                      values={{ count: data.split(',').length - 1 }}
                    />
                  </Typography>
                ) : (
                  <span />
                )}
                <Link href="#" underline="none" onClick={onOpen}>
                  <TransButton i18nKey="addEdit" />
                </Link>
              </Stack>
            }
          />
        </Box>
      </FormControl>
      {/* TODO: Remove the name check, replace it with {children} and start passing modals in as children */}
      {/* Probably need to create a separate useModal call for each modal*/}
      {name === 'lineIds' ? (
        <LinesModal isOpen={isOpen} onSubmit={handleSubmit} onClose={onClose} />
      ) : name === 'tripIds' ? (
        <TripsModal isOpen={isOpen} onSubmit={handleSubmit} onClose={onClose} />
      ) : name === 'fromStopIds' || name === 'toStopIds' ? (
        <StopsModal isOpen={isOpen} onSubmit={handleSubmit} onClose={onClose} />
      ) : name === 'inventoryClassIds' ? (
        <InventoryClassesModal
          isOpen={isOpen}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      ) : name === 'ancillaries' ? (
        <AncillariesModal
          isOpen={isOpen}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      ) : null}
    </>
  );
}
export const ModalTextfieldArray = <TValue extends Classifier<number>>(
  props: FormFieldProps<TValue> & {}
) => <FormField component={TextfieldWithSearchAdapter} {...props} />;
