import { useEffect, useMemo, useState } from 'react';
import { LineNumber, Service } from 'dto/lines';
import {
  fetchLineNumbers,
  fetchServices,
} from 'features/passengers/passengersService';
import { SelectValue } from '@fleet/shared/mui/Select';
import { SelectOption } from '@fleet/shared';

export const useTripOptionsSearch = (carrierId: number | undefined) => {
  const [lineNumbers, setLineNumbers] = useState<Array<LineNumber>>([]);
  const [services, setServices] = useState<Array<Service>>([]);

  const lineNumberOptions = useMemo(
    () => lineNumbers.map(({ id, name }) => ({ value: id, label: name })),
    [lineNumbers]
  );

  const serviceOptions = useMemo(
    () =>
      services.reduce((acc, { code }) => {
        if (code) {
          acc.push({ value: code, label: code });
        }

        return acc;
      }, [] as Array<SelectOption<SelectValue>>),
    [services]
  );

  useEffect(() => {
    const fetchData = async (carrierId: number) => {
      if (!carrierId) {
        return;
      }

      const [lineNumbers, services] = await Promise.all([
        fetchLineNumbers(carrierId),
        fetchServices(carrierId),
      ]);

      setLineNumbers(lineNumbers);
      setServices(services);
    };

    if (carrierId) {
      fetchData(carrierId);
    }
  }, [carrierId]);

  return { lineNumberOptions, serviceOptions };
};
