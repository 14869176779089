import {
  Button,
  Checkbox,
  FormProvider,
  Loadable,
  Modal,
  SelectField,
  Table,
  TableColumns,
  TextField,
  useForm,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useFilters, useRowSelect, useTable } from 'react-table';
import { Icon } from '@fleet/shared/mui';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { ClassificationGroup } from 'dto/classification';
import { fetchLines } from 'features/notifications/notificationsService';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { useSelector } from 'react-redux';
import type { Classifier } from '@fleet/shared/dto/classifier';

interface LinesSelectionModalProps {
  isOpen: boolean;
  onSubmit: (value: Array<Classifier<number>>) => void;
  onClose: () => void;
}
export interface LineRow {
  id: number;
  number: string;
  name: string;
  carrier: string;
}

interface LinesSearchParams {
  carrierId: string;
  lineName: string;
  lineNumber: number;
}

export const LinesModal: FC<LinesSelectionModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<LineRow[]>([]);

  const carrierOptions = useClassificationOptions(ClassificationGroup.CARRIER);
  const carrierMap = useMemo(
    () => new Map(carrierOptions.map((option) => [option.value, option.label])),
    [carrierOptions]
  );
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  const columns = useMemo<TableColumns<LineRow>>(
    () => [
      {
        accessor: 'number',
        Header: <TransTableHead i18nKey="lineNumber" />,
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="lineName" />,
      },
      {
        accessor: 'carrier',
        Header: <TransTableHead i18nKey="carrier" />,
      },
    ],
    []
  );

  const table = useTable<LineRow>(
    {
      data,
      columns,
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const handleSearchSubmit = useCallback(
    async (filter) => {
      setIsLoading(true);

      const values = await fetchLines(filter);
      const mappedValues: LineRow[] = values.map((line) => ({
        id: line.id,
        number: line.number,
        name: line.name,
        carrier: carrierMap.get(line.carrierId!) ?? '-',
      }));
      setData(mappedValues);

      setIsLoading(false);
    },
    [carrierMap]
  );

  const { form, handleSubmit, dirty, submitting } = useForm<LinesSearchParams>({
    onSubmit: handleSearchSubmit,
    subscription: { dirty: true, submitting: true },
  });

  const handleSelectedFilterToggle = useCallback(() => {
    // TODO: Implement
  }, []);

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={<TransTitle i18nKey="selectLines" />}
      actionButton={
        <Button
          variant="contained"
          type="submit"
          onClick={() => {
            onSubmit(
              table.selectedFlatRows.map((row) => ({
                name: row.original.name,
                id: row.original.id,
              }))
            );
          }}
          startIcon={<Icon name="check" size={20} />}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
      maxWidth="md"
      fullWidth
    >
      <Loadable loading={isLoading}>
        <Table
          caption={
            <>
              <FormProvider {...form}>
                <form onSubmit={handleSubmit}>
                  <Grid container columns={3} spacing={2} alignItems="flex-end">
                    <Grid item xs={1}>
                      <SelectField
                        name="carrierId"
                        label={<TransField i18nKey="carrier" />}
                        options={carrierOptions}
                        defaultValue={currentBusinessEntityId}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        name="lineNumber"
                        label={<TransField i18nKey="lineNumber" />}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        name="lineName"
                        label={<TransField i18nKey="lineName" />}
                      />
                    </Grid>
                    <Grid
                      item
                      xs="auto"
                      sx={{ ml: 'auto' }}
                      alignItems="flex-end"
                    >
                      <Stack direction="row" spacing={2}>
                        <Button
                          sx={{ whiteSpace: 'nowrap' }}
                          variant="text"
                          onClick={handleReset}
                        >
                          <TransButton i18nKey="resetFilters" />
                        </Button>

                        <Button
                          variant="contained"
                          type="submit"
                          startIcon={<Icon name="search" size={20} />}
                          disabled={!dirty || submitting}
                        >
                          <TransButton i18nKey="search" />
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
              <Divider sx={{ pt: '24px' }} />
              <CardContent sx={{ pl: '0' }}>
                <Stack
                  direction="row"
                  alignItems="baseline"
                  justifyContent={'space-between'}
                  spacing={2}
                >
                  <Box sx={{ width: '110px' }}>
                    <Typography variant="subtitle" fontWeight={700}>
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <TransSubtitle
                        i18nKey="linesQty"
                        values={{ count: table.selectedFlatRows.length }}
                      />
                    </Typography>
                  </Box>
                  <Checkbox
                    label={'Show only selected rows'}
                    onChange={handleSelectedFilterToggle}
                    defaultChecked={false}
                  />
                </Stack>
              </CardContent>
            </>
          }
          table={table}
        />
      </Loadable>
    </Modal>
  );
};
