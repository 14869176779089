import React, { FC } from 'react';
import {
  Button,
  FormProvider,
  Icon,
  Modal,
  TabPanel,
  Tabs,
  useForm,
} from '@fleet/shared';
import { validatedNotificationsSelector } from 'features/notifications/notificationsSelectors';
import { useSelector } from 'store/utils';
import { Typography } from '@mui/material';
import { ValidatedNotificationByLanguage } from 'dto/api/notifications-management';
import { ValidationTab } from 'routes/SendNotifications/content/validation/ValidationTab';

interface ValidationModalProps {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const ValidationModal: FC<ValidationModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const validatedNotifications = useSelector(validatedNotificationsSelector);

  const { form, handleSubmit } = useForm({
    onSubmit,
  });

  return (
    <Modal
      title={'Template validation'}
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      actionButton={
        <Button
          variant="contained"
          type="submit"
          startIcon={<Icon name="mail" size={20} />}
          onClick={() => handleSubmit()}
        >
          {/*TODO: Translate*/}
          Send notifications
        </Button>
      }
    >
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Tabs
            value={
              validatedNotifications.length
                ? validatedNotifications[0].language?.id
                : ''
            }
          >
            {validatedNotifications.map((tab, i) => {
              const languageId = tab.language?.id || '';
              const languageName = tab.language?.name || '';
              return (
                <TabPanel
                  value={languageId}
                  key={languageId}
                  label={
                    <Typography>
                      {languageName}
                      &nbsp;
                      {hasAnyErrors(tab) ? ( // TODO: Replace with green and red dots
                        <Icon name={'error-circle'} color="error" />
                      ) : (
                        <Icon name={'check-circle'} color="success" />
                      )}
                    </Typography>
                  }
                >
                  <ValidationTab tabData={tab} index={i} />
                </TabPanel>
              );
            })}
          </Tabs>
        </form>
      </FormProvider>
    </Modal>
  );
};

const hasAnyErrors = (tab: ValidatedNotificationByLanguage) =>
  tab.emailContentError ||
  tab.smsContentError ||
  tab.pushNotificationContentError;
