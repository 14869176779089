import { classificationReducer } from 'features/classification/classificationReducer';
import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from 'features/loading/loadingReducer';
import { commonReducer } from 'features/common/commonReducer';
import { passengersReducer } from 'features/passengers/passengersReducer';
import { reAccommodateReducer } from 'features/reAccommodate/reAccommodateReducer';
import { notificationsReducer } from 'features/notifications/notificationsReducer';

export default combineReducers({
  loading: loadingReducer,
  classification: classificationReducer,
  common: commonReducer,
  passengers: passengersReducer,
  reAccommodate: reAccommodateReducer,
  notifications: notificationsReducer,
});
