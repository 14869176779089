import React, { FC } from 'react';
import { Button } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { Box, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { notificationSummarySelector } from 'features/notifications/notificationsSelectors';
import { setActiveStep } from 'features/notifications/notificationsActions';
import { Step } from 'dto/stepper';

export const NotificationsSummary: FC = () => {
  const dispatch = useDispatch();
  const summaryData = useSelector(notificationSummarySelector);
  const handleCreateMoreNotifications = () => {
    // dispatch(resetNotificationsStore());
    dispatch(setActiveStep(Step.Filters));
  };
  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack>
          <Box sx={{ textAlign: 'center', mb: 1 }}>
            {/* TODO: svg to shared library */}
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="40"
                cy="40"
                r="40"
                fill="#028E45"
                fill-opacity="0.1"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M53.3333 31.6668L26.6666 31.6668L26.6666 48.3335H53.3333V31.6668ZM26.6666 28.3335C24.8257 28.3335 23.3333 29.8259 23.3333 31.6668L23.3333 48.3335C23.3333 50.1744 24.8257 51.6668 26.6666 51.6668H53.3333C55.1743 51.6668 56.6666 50.1744 56.6666 48.3335V31.6668C56.6666 29.8259 55.1743 28.3335 53.3333 28.3335L26.6666 28.3335Z"
                fill="#028E45"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M39.1166 41.4135L25.7833 33.0802L27.5499 30.2535L40 38.0348L52.45 30.2535L54.2166 33.0802L40.8833 41.4135C40.3428 41.7513 39.6571 41.7513 39.1166 41.4135Z"
                fill="#028E45"
              />
              <circle cx="54" cy="50" r="9.5" fill="#028E45" stroke="#E6F4EC" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M58.3536 46.6464C58.5488 46.8417 58.5488 47.1583 58.3536 47.3536L52.8536 52.8536C52.6583 53.0488 52.3417 53.0488 52.1464 52.8536L49.6464 50.3536C49.4512 50.1583 49.4512 49.8417 49.6464 49.6464C49.8417 49.4512 50.1583 49.4512 50.3536 49.6464L52.5 51.7929L57.6464 46.6464C57.8417 46.4512 58.1583 46.4512 58.3536 46.6464Z"
                fill="white"
              />
            </svg>
          </Box>

          <Stack>
            <Typography sx={{ textAlign: 'center' }}>
              Notification mass sending has been started.
            </Typography>
            <Typography>
              More info about mass sending could be found in{' '}
              {/* TODO: Replace with button or figure out how to inject anchor tags to translated text */}
              <a href="#">Process logs</a>.
            </Typography>
            <Typography>
              {`Notifications are sent to ${summaryData.emailNotificationsCount} emails and ${summaryData.smsNotificationsCount} phone numbers.`}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Stack
        direction="row"
        sx={{ ml: 'auto', mr: 4, mt: 3, mb: 2, justifyContent: 'end' }}
        spacing={1}
      >
        <Button onClick={() => console.log('View process logs')} variant="text">
          <TransButton i18nKey="viewProcessLogs" />
        </Button>
        <Button
          onClick={() => console.log('View notification history')}
          variant="text"
        >
          <TransButton i18nKey="viewNotificationHistory" />
        </Button>
        <Button
          icon="add"
          onClick={() => handleCreateMoreNotifications()}
          sx={{ ml: 2 }}
        >
          {/*TODO: Clear store and setActiveStep to Step.Filters */}
          <TransButton i18nKey="createMoreNotifications" />
        </Button>
      </Stack>
    </>
  );
};
