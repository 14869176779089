import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  Checkbox,
  Table,
  TableColumns,
  TableDragDropProps,
  useDraggable,
  useFormContext,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { useTable } from 'react-table';
import {
  currentTemplateSelector,
  notificationChannelsSelector,
} from 'features/notifications/notificationsSelectors';
import { useSelector } from 'store/utils';
import { NotificationChannel } from 'dto/api/notifications-management';

interface NotificationChannelsProps {}
export const NotificationChannels: FC<NotificationChannelsProps> = () => {
  const currentTemplate = useSelector(currentTemplateSelector);
  const notificationChannels = useSelector(notificationChannelsSelector);
  const [data, setData] = useState<NotificationChannel[]>([]);
  const form = useFormContext();

  useEffect(() => {
    if (currentTemplate && currentTemplate.notificationChannels) {
      setData(currentTemplate.notificationChannels);
    } else {
      setData(notificationChannels);
    }
  }, [currentTemplate, notificationChannels]);

  const handleCheckboxChange = useCallback(
    (rowIndex: number, fieldName: string, newValue: boolean) => {
      const newData = data.map((item, index) => {
        if (index === rowIndex) {
          return { ...item, [fieldName]: newValue };
        }
        return item;
      });
      setData(newData);

      form.change('notificationChannels', newData);
    },
    [data, form]
  );

  const columns = useMemo<TableColumns<NotificationChannel>>(
    () => [
      {
        id: 'isActive',
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="isActive" />,
        Cell: ({ row: { original, index }, column: { id } }) => (
          <Checkbox
            size="small"
            checked={original.isActive}
            onChange={(e) => handleCheckboxChange(index, id, e.target.checked)}
          />
        ),
        width: 'auto',
      },
      {
        accessor: 'orderNumber',
        Header: <TransTableHead i18nKey="priority" />,
        width: 'auto',
      },
      {
        id: 'channel.id',
        accessor: ({ channel }) => channel?.name,
        Header: <TransTableHead i18nKey="medium" />,
        width: 'auto',
      },
      {
        id: 'sendEvenWhenHigherPriorityExists',
        accessor: 'sendEvenWhenHigherPriorityExists',
        Header: <TransTableHead i18nKey="sendEvenWhenHigherPriorityExists" />,
        Cell: ({ row: { index, original }, column: { id } }) =>
          index !== 0 ? (
            <Checkbox
              size="small"
              checked={original.sendEvenWhenHigherPriorityExists}
              onChange={(e) =>
                handleCheckboxChange(index, id, e.target.checked)
              }
            />
          ) : (
            <></>
          ),
        width: 'auto',
      },
    ],
    [handleCheckboxChange]
  );

  const handleDragEnd = useCallback<
    TableDragDropProps<NotificationChannel>['onDragEnd']
  >(
    ({ data }) => {
      const updatedData: NotificationChannel[] = data.map((item, index) => {
        return { ...item, orderNumber: index + 1 };
      });

      setData(updatedData);

      form.change('notificationChannels', updatedData);
    },
    [form]
  );

  const table = useTable({ columns, data }, useDraggable);
  return (
    <Stack sx={{ mb: 2, px: 2 }}>
      <Typography variant="subtitle" fontWeight="700" sx={{ my: 2 }}>
        <TransSubtitle i18nKey="notificationChannels" />
      </Typography>
      <Table table={table} draggable onDragEnd={handleDragEnd} />
    </Stack>
  );
};
