import React, { FC, useEffect } from 'react';
import type { EmailValidationData } from 'routes/SendNotifications/content/validation/ValidationTab';
import { Grid, Stack, Typography } from '@mui/material';
import { TextField } from '@fleet/shared';
import DOMPurify from 'dompurify';
import { getSuccessOrFailureText } from 'helpers/templateValidation';
import { TransTitle } from 'i18n/trans/title';
interface EmailValidationProps {
  data: EmailValidationData;
  index: number;
}

const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};
const updateIframeContent = (html: string) => {
  const iframe = document.getElementById(
    'emailTemplateValidationPreview'
  ) as HTMLIFrameElement;

  const sanitizedHtml = DOMPurify.sanitize(html);
  const decodedHtml = decodeHtml(sanitizedHtml);

  iframe?.contentWindow?.document.open();
  iframe?.contentWindow?.document.write(decodedHtml);
  iframe?.contentWindow?.document.close();
};

export const EmailValidation: FC<EmailValidationProps> = ({ data, index }) => {
  useEffect(() => {
    if (data.content) {
      updateIframeContent(data.content);
    }
  }, [data]);

  return (
    <>
      <Stack direction="row" sx={{ mb: 2 }}>
        <Typography fontWeight={900}>
          <TransTitle i18nKey="emailTemplate" />
          {':'}
        </Typography>
        {getSuccessOrFailureText(data.contentError)}
      </Stack>

      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Typography fontWeight={700}>From:</Typography>
        </Grid>
        <Grid item xs={11}>
          {/*  TODO: Need to render conditionally, currently nulls in UI */}
          {`${data.senderName} <${data.senderEmail}>`}
        </Grid>
        <Grid item xs={1}>
          <Typography fontWeight={700}>Subject:</Typography>
        </Grid>
        <Grid item xs={11}>
          {data.subject}
        </Grid>
      </Grid>

      <Stack>
        <Typography fontWeight={700} sx={{ mt: 3, mb: 1 }}>
          {/* TODO: Translate */}
          Content (text/plain):
        </Typography>
        <TextField
          name={`notificationsByLanguage[${index}].emailContent`}
          defaultValue={data.content}
          disabled
          multiline
          rows={8}
        />
      </Stack>

      <Stack>
        <Typography fontWeight={700} sx={{ mt: 3, mb: 1 }}>
          {/* TODO: Translate */}
          Content (text/html):
        </Typography>
        <iframe
          id="emailTemplateValidationPreview"
          style={{
            width: '100%',
            height: '215px',
            border: '1px solid #E1E1E1',
            borderRadius: '4px',
          }}
        ></iframe>
      </Stack>
    </>
  );
};
