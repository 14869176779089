import { createSelector, selector } from 'store/utils';

export const selectPassengersFilterByTrip = selector(
  (state) => state.passengers.filterByTrips
);
export const passengersFilterByTripSelector = createSelector(
  selectPassengersFilterByTrip
);

export const selectPassengersByTrip = selector(
  (state) => state.passengers.listByTrips
);
export const passengersSelectorByTrip = createSelector(selectPassengersByTrip);

export const selectPassengersTab = selector((state) => state.passengers.tab);
export const passengersTabSelector = createSelector(selectPassengersTab);
