import type { FC } from 'react';
import { Button, CardHeader, Icon, Layout, Loadable } from '@fleet/shared';
import { Divider, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { NotificationFilters } from './filters/NotificationFilters';
import { NotificationContent } from './content/NotificationContent';
import { NotificationAddressees } from 'routes/SendNotifications/NotificationAddressees';
import { TransButton } from 'i18n/trans/button';
import { NotificationsSummary } from 'routes/SendNotifications/NotificationsSummary';
import { useSelector } from 'store/utils';
import { activeStepSelector } from 'features/notifications/notificationsSelectors';
import { TransLabel } from 'i18n/trans/label';

interface SendNotificationsProps {}

const steps = [
  <TransLabel i18nKey="notificationFilters" />,
  <TransLabel i18nKey="notificationAddressees" />,
  <TransLabel i18nKey="notificationContent" />,
  <TransLabel i18nKey="notificationSummary" />,
];

export const SendNotifications: FC<SendNotificationsProps> = () => {
  const activeStep = useSelector(activeStepSelector);
  const stepComponents = useMemo(
    () => ({
      0: <NotificationFilters />,
      1: <NotificationAddressees />,
      2: <NotificationContent />,
      3: <NotificationsSummary />, // hidden from stepper
    }),
    []
  );

  return (
    <Loadable loading={false}>
      <Layout
        header={
          <CardHeader
            sx={{ p: 3, textAlign: 'right' }}
            title={'Send notifications'}
          >
            <Button
              sx={{ padding: 0, alignSelf: 'end' }}
              disabled
              variant="text"
              size="small"
              startIcon={<Icon name="question" />}
            >
              <Typography variant="body2">
                <TransButton i18nKey="help" />
              </Typography>
            </Button>
          </CardHeader>
        }
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '1156px',
          margin: 'auto',
        }}
        height={'auto'}
      >
        <>
          <Stepper activeStep={activeStep} alternativeLabel sx={{ p: 3 }}>
            {steps.slice(0, 3).map((label, i) => (
              <Step key={i}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Divider sx={{ borderWidth: '1px' }} />
          {stepComponents[activeStep]}
        </>
      </Layout>
    </Loadable>
  );
};
