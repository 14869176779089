import { Button, Icon } from '@fleet/shared';
import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TransButton } from 'i18n/trans/button';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { ReAccommodateConfirmChangesCard } from 'routes/Passengers/ReAccommodate/ConfirmChanges/ReAccommodateConfirmChangesCard';
import { ReAccommodateStepProps } from 'routes/Passengers/ReAccommodate/ReAccommodateSteps';

// ReAccommodateConfirmChangesCard is mocked. Just styles
const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  actions: {
    padding: theme.spacing(2),
    '& .MuiButton-text': { padding: 0 },
  },
}));

export const ReAccommodateConfirmChanges: FC<ReAccommodateStepProps> = ({
  onCancelReAccommodate,
}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <div className={classes.content}>
        <Stack direction="row" spacing={5} justifyContent="center">
          <ReAccommodateConfirmChangesCard isSuccessful />
          <ReAccommodateConfirmChangesCard isSuccessful={false} />
        </Stack>
      </div>
      <Stack
        direction="row"
        justifyContent="flex-end"
        className={classes.actions}
        spacing={2}
      >
        <>
          <Button variant="text" onClick={onCancelReAccommodate}>
            <TransButton i18nKey="viewUnresolvedPassengers" />
          </Button>
          <Button variant="text">
            <TransButton i18nKey="viewLogs" />
          </Button>
          <Button
            startIcon={<Icon name="arrow-right" />}
            onClick={() => history.push('/send-notifications')}
          >
            <TransButton i18nKey="sendNotifications" />
          </Button>
        </>
      </Stack>
    </>
  );
};
