import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { RadioGroupField } from '@fleet/shared';
import { EmailTabs } from './EmailTabs';
import { TransLabel } from 'i18n/trans/label';
import { MediumDataEmail } from 'dto/mediums';
import { useSelector } from 'store/utils';
import { notificationLanguagesSelector } from 'features/notifications/notificationsSelectors';
import { mergeRequiredLanguagesWithEmailTemplateData } from 'helpers/mediums';
import { Highlight } from 'components/Highlight';

interface EmailProps {
  hasRecipients: boolean;
  templateEmailData: Array<MediumDataEmail>;
}

const OPTIONS = [
  // BE currently doesn't support this so I don't know what the values should be
  {
    name: 'unique',
    label: 'Only unique emails/phone numbers (w/o tickets)',
    value: 'unique',
  },
  {
    value: 'all',
    label: 'All emails/phone numbers (with tickets)',
    name: 'all',
  },
];
export const Email: FC<EmailProps> = ({ hasRecipients, templateEmailData }) => {
  const notificationLanguages =
    useSelector(notificationLanguagesSelector) || [];

  const tabData = mergeRequiredLanguagesWithEmailTemplateData(
    templateEmailData,
    notificationLanguages
  );

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Typography variant="subtitle" fontWeight={700}>
        <TransSubtitle i18nKey="email" />
      </Typography>
      {hasRecipients ? (
        <>
          <RadioGroupField
            name={'sendNotificationTo'}
            options={OPTIONS}
            label={
              <Typography fontWeight={400}>
                <TransLabel i18nKey="sendNotificationTo" />:
              </Typography>
            }
            defaultValue={OPTIONS[0].value}
            inline
          />
          <Highlight>
            <EmailTabs tabData={tabData} />
          </Highlight>
        </>
      ) : (
        <Highlight>
          <Typography>
            <TransSubtitle i18nKey="noEmailReceivers" />
          </Typography>
        </Highlight>
      )}
    </Stack>
  );
};
