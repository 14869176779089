import { Icon, Theme } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TransTitle } from 'i18n/trans/title';
import { FC } from 'react';

const useStyles = makeStyles<Theme, ReAccommodateConfirmChangesCardProps>(
  (theme) => {
    const getColor = (isSuccessful: boolean) =>
      isSuccessful ? theme.palette.success.main : theme.palette.error.main;

    return {
      root: ({ isSuccessful }) => ({
        padding: theme.spacing(3),
        width: 400,
        border: `2px solid ${getColor(isSuccessful)}`,
        borderRadius: 8,
      }),
      icon: ({ isSuccessful }) => {
        const color = getColor(isSuccessful);

        return {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 80,
          height: 80,
          backgroundColor: `${color}1a`,
          borderRadius: '100%',
          '& .Icon-root': {
            color,
          },
        };
      },
    };
  }
);

interface ReAccommodateConfirmChangesCardProps {
  isSuccessful: boolean;
}

export const ReAccommodateConfirmChangesCard: FC<ReAccommodateConfirmChangesCardProps> =
  ({ isSuccessful }) => {
    const classes = useStyles({ isSuccessful });

    return (
      <Stack className={classes.root} direction="row" spacing={3}>
        <div className={classes.icon}>
          <Icon
            name={isSuccessful ? 'ticket-change' : 'ticket-cancel'}
            size={40}
          />
        </div>
        <Typography variant="h2">
          <TransTitle
            i18nKey={
              isSuccessful
                ? 'passengersReAccommodatedQty'
                : 'passengersUnresolvedQty'
            }
            values={{
              count: isSuccessful ? 100 : 20,
            }}
            tOptions={{ postProcess: 'interval' }}
          />
        </Typography>
      </Stack>
    );
  };
