import {
  Button,
  Icon,
  SearchResult,
  Table,
  TableColumns,
  currentDateTimeFormat,
  formatDate,
  useIndeterminateRowSelectCheckbox,
  useRowActive,
} from '@fleet/shared';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import { CardContent, Divider, Stack, Typography } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { Passenger } from 'dto/passengers';
import { passengersLoadingSelector } from 'features/loading/loadingSelectors';
import { setReAccommodateCurrentTripsList } from 'features/reAccommodate/reAccommodateActions';
import { passengersTabSelector } from 'features/passengers/passengersSelectors';
import { passengersSearchCallbacks } from 'hooks/usePassengersSearchForm';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { useMemo, FC, useCallback } from 'react';
import { useHistory } from 'react-router';
import { usePagination, useRowSelect, useTable } from 'react-table';
import { PassengersSearch } from 'routes/Passengers/Search/PassengersSearch';
import { useDispatch, useSelector } from 'store/utils';

interface PassengersTableProps {}

export const PassengersTable: FC<PassengersTableProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tab = useSelector(passengersTabSelector);
  const { passengersSelector, getPassengers, selectPassengersFilter } =
    passengersSearchCallbacks[tab];
  const passengers = useSelector(passengersSelector);
  const filter = useSelector(selectPassengersFilter);
  const loading = useSelector(passengersLoadingSelector);
  const data = useMemo(() => passengers?.items ?? [], [passengers]);

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      dispatch(getPassengers({ ...filter, ...paginationParams })),
    [dispatch, filter, getPassengers]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (passengers) {
        const { offset } = passengers;
        return offset / pageSize;
      }

      return 0;
    },
    [passengers]
  );

  const columns = useMemo<TableColumns<Passenger>>(
    () => [
      {
        id: 'name',
        accessor: ({ passenger: { firstName, lastName } }) =>
          `${firstName} ${lastName}`,
        Header: <TransTableHead i18nKey="name" />,
        width: 110,
      },
      {
        accessor: 'bookingNumber',
        Header: <TransTableHead i18nKey="bookingNumber" />,
        width: 110,
      },
      {
        accessor: 'admissionNumber',
        Header: <TransTableHead i18nKey="admissionNumber" />,
        width: 110,
      },
      {
        id: 'passengerCategory',
        accessor: ({ passenger: { category } }) => category.name,
        Header: <TransTableHead i18nKey="passengerCategory" />,
        width: 110,
      },
      {
        id: 'admissionStatus',
        accessor: ({ admissionStatus }) => admissionStatus.name,
        Header: <TransTableHead i18nKey="status" />,
        width: 70,
      },
      {
        id: 'tripName',
        accessor: ({ trip: { name, departureDateTime } }) => (
          <>
            <Typography variant="body2">
              {formatDate(departureDateTime, currentDateTimeFormat)}
            </Typography>
            <Typography variant="body2">{name}</Typography>
          </>
        ),
        Header: <TransTableHead i18nKey="tripName" />,
        width: 160,
      },
      {
        id: 'vehicleCarriage',
        accessor: ({ vehicle: { name, carriage } }) => `${name} / ${carriage}`,
        Header: <TransTableHead i18nKey="vehicleCarriage" />,
        width: 120,
      },
      {
        id: 'originStopName',
        accessor: ({ journeyLeg: { originStop } }) => originStop.name,
        Header: <TransTableHead i18nKey="originStopZone" />,
        width: 120,
      },
      {
        id: 'departureTime',
        accessor: ({ journeyLeg: { departureTime } }) =>
          departureTime
            ? formatDate(departureTime, currentLocaleConfiguration.timeFormat)
            : ' - ',
        Header: <TransTableHead i18nKey="shortDepartureTime" />,
        width: 70,
      },
      {
        id: 'destinationStopName',
        accessor: ({ journeyLeg: { destinationStop } }) => destinationStop.name,
        Header: <TransTableHead i18nKey="destinationStopZone" />,
        width: 120,
      },
      {
        id: 'arrivalTime',
        accessor: ({ journeyLeg: { departureTime, arrivalTime } }) => {
          if (!departureTime || !arrivalTime) {
            return ' - ';
          }

          const difference = differenceInDays(
            new Date(departureTime),
            new Date(arrivalTime)
          );

          return (
            <>
              <Typography variant="body2">
                {formatDate(arrivalTime, currentLocaleConfiguration.timeFormat)}
              </Typography>
              {difference > 0 && <Typography>{`(+${difference})`}</Typography>}
            </>
          );
        },
        Header: <TransTableHead i18nKey="shortArrivalTime" />,
        width: 70,
      },
    ],
    []
  );

  const table = useTable<Passenger>(
    {
      data,
      columns,
      pageCount: -1,
      total: passengers?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const { selectedFlatRows } = table;

  const handleReAccommodateClick = useCallback(() => {
    dispatch(
      setReAccommodateCurrentTripsList(
        selectedFlatRows.map(({ original }) => original)
      )
    );

    history.replace('/passengers-allocation/re-accommodate');
  }, [dispatch, history, selectedFlatRows]);

  return (
    <>
      <PassengersSearch />
      <Divider />
      <SearchResult results={!!data.length} loading={loading}>
        <Table
          caption={
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" gap={2}>
                  <Typography variant="subtitle" fontWeight="700">
                    <TransSubtitle i18nKey="searchResults" />
                  </Typography>
                  {Boolean(passengers?.totalCount) && (
                    <Typography variant="body2" color="text.secondary">
                      <TransSubtitle
                        i18nKey="passengersQty"
                        values={{
                          count: passengers ? passengers.totalCount : 0,
                        }}
                        tOptions={{ postProcess: 'interval' }}
                      />
                    </Typography>
                  )}
                  {Boolean(selectedFlatRows.length) && (
                    <Button
                      variant="text"
                      startIcon={<Icon name="ticket-change" />}
                      onClick={handleReAccommodateClick}
                      sx={{ p: 0 }}
                    >
                      <TransButton i18nKey="reAccommodation" />
                    </Button>
                  )}
                </Stack>
                <Typography variant="body2" color="text.secondary">
                  <TransSubtitle
                    i18nKey="selectedQty"
                    values={{
                      count: selectedFlatRows.length,
                    }}
                  />
                </Typography>
              </Stack>
            </CardContent>
          }
          table={table}
        />
      </SearchResult>
    </>
  );
};
