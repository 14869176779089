import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransNav = createTrans({
  passengersAllocation: (
    <Trans
      i18nKey="nav.passengersAllocation"
      defaults="Passengers allocation"
    />
  ),
  sendNotifications: (
    <Trans i18nKey="nav.sendNotifications" defaults="Send notifications" />
  ),
});
