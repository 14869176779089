import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import {
  PassengerSearchGroup,
  PostPassengersByTripsResponse,
  PassengerFilterByTrips,
} from 'dto/passengers';
import omit from 'lodash/omit';
import { api } from '@fleet/shared';

export const setPassengersFilterTab = createAction<PassengerSearchGroup>(
  'passengers/setPassengersFilterTab'
);

export const setPassengersFilterByTrips = createAction<
  Partial<PassengerFilterByTrips>
>('passengers/setPassengersFilterByTrips');

export const getPassengersByTrips = createAsyncThunk<
  PostPassengersByTripsResponse,
  Partial<PassengerFilterByTrips>
>('passengers/getPassengersByTrips', async (values, { getState, dispatch }) => {
  if (values) {
    dispatch(setPassengersFilterByTrips(values));
  }

  const {
    passengers: { filterByTrips },
    common: { currentBusinessEntityId },
  } = getState();

  const filter = {
    carrierId: currentBusinessEntityId,
    ...omit(filterByTrips, [
      'originStop',
      'destinationStop',
      'stopOne',
      'stopTwo',
    ]),
    originStopId: filterByTrips.originStop?.id,
    destinationStopId: filterByTrips.destinationStop?.id,
    stopOneId: filterByTrips.stopOne?.id,
    stopTwoId: filterByTrips.stopTwo?.id,
  };

  return (
    await api.post<PostPassengersByTripsResponse>(
      `/trips/admissions/search`,
      filter
    )
  ).data;
});
