import { Table, TableColumns, formatDate, useTable } from '@fleet/shared';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import { ReAccommodateDetailedTripStop } from 'dto/reAccommodate';
import { getReAccommodateNewDetailedTrip } from 'features/reAccommodate/reAccommodateActions';
import { reAccommodateNewDetailedTripsSelector } from 'features/reAccommodate/reAccommodateSelectors';
import { TransField } from 'i18n/trans/field';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';

interface ReAccommodateSelectTripSubTableProps {
  tripId: number;
}

export const ReAccommodateSelectTripSubTable: FC<ReAccommodateSelectTripSubTableProps> =
  ({ tripId }) => {
    const dispatch = useDispatch();
    const detailedTrips = useSelector(reAccommodateNewDetailedTripsSelector);
    const data = useMemo(
      () => detailedTrips?.[tripId]?.routeStops ?? [],
      [detailedTrips, tripId]
    );

    const columns: TableColumns<ReAccommodateDetailedTripStop> = useMemo(
      () => [
        {
          id: 'arrival',
          accessor: ({ plannedArrivalTime }) =>
            plannedArrivalTime
              ? formatDate(
                  plannedArrivalTime,
                  currentLocaleConfiguration.timeFormat
                )
              : ' - ',
          Header: <TransTableHead i18nKey="arrival" />,
        },
        {
          id: 'departure',
          accessor: ({ plannedDepartureTime }) =>
            plannedDepartureTime
              ? formatDate(
                  plannedDepartureTime,
                  currentLocaleConfiguration.timeFormat
                )
              : ' - ',
          Header: <TransTableHead i18nKey="departure" />,
        },
        {
          id: 'name',
          accessor: ({ stop }) => stop?.name ?? ' - ',
          Header: <TransTableHead i18nKey="stop" />,
          width: '40%',
        },
        // {
        //   id: 'entering',
        //   accessor: ({ entering }) => `${entering || '-'}`,
        //   Header: <TransTableHead i18nKey="entering" />,
        // },
        // {
        //   id: 'leaving',
        //   accessor: ({ leaving }) => `${leaving || '-'}`,
        //   Header: <TransTableHead i18nKey="leaving" />,
        // },
        // {
        //   id: 'occupancy',
        //   accessor: ({ inside, total }) => `${inside || '-'}/${total}`,
        //   Header: <TransTableHead i18nKey="occupancy" />,
        // },
        // {
        //   id: 'availability',
        //   accessor: ({ inside, total }) => `${total - (inside || 0)}`,
        //   Header: <TransTableHead i18nKey="availability" />,
        // },
      ],
      []
    );

    useEffect(() => {
      dispatch(getReAccommodateNewDetailedTrip(tripId));
    }, [dispatch, tripId]);

    const table = useTable<ReAccommodateDetailedTripStop>({
      data,
      columns,
    });

    return (
      <Table table={table} emptyLabel={<TransField i18nKey="noStops" />} />
    );
  };
