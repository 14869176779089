import React, { FC } from 'react';
import { ValidatedNotificationByLanguage } from 'dto/api/notifications-management';
import { Divider, Stack } from '@mui/material';
import { Nullable } from 'helpers/types';
import { EmailValidation } from 'routes/SendNotifications/content/validation/EmailValidation';
import { SmsValidation } from 'routes/SendNotifications/content/validation/SmsValidation';

interface ValidationTabProps {
  tabData: ValidatedNotificationByLanguage;
  index: number;
}

export const ValidationTab: FC<ValidationTabProps> = ({ tabData, index }) => {
  // TODO: Create a type that holds everything but emailContentError, use it as a base for Enriched* types and *Validation types
  const emailValidationData = {
    senderEmail: tabData.emailSenderEmail,
    subject: tabData.emailSubject,
    senderName: tabData.emailSenderName,
    content: tabData.emailContent,
    contentError: tabData.emailContentError,
  };
  const smsValidationData = {
    senderName: tabData.smsSenderName,
    content: tabData.smsContent,
    contentError: tabData.smsContentError,
  };
  // const pushValidationData = {
  //   title: tabData.pushNotificationTitle,
  //   content: tabData.pushNotificationContent,
  //   contentError: tabData.pushNotificationContentError,
  // };
  return (
    <Stack>
      <EmailValidation data={emailValidationData} index={index} />
      <Divider sx={{ my: 4 }} />
      <SmsValidation data={smsValidationData} index={index} />
      {/*<Divider sx={{ my: 4 }} />*/}
      {/*<PushValidation data={pushValidationData} index={index} />*/}
    </Stack>
  );
};

interface ValidationBase {
  content: Nullable<string>;
  contentError: Nullable<string>;
}
export interface EmailValidationData extends ValidationBase {
  senderEmail: Nullable<string>;
  subject: Nullable<string>;
  senderName: Nullable<string>;
}

export interface SmsValidationData extends ValidationBase {
  senderName: Nullable<string>;
}

export interface PushValidationData extends ValidationBase {
  title: Nullable<string>;
}
