import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import { Step } from 'dto/stepper';
import {
  MappedNotificationContentFormValues,
  NotificationLanguage,
  NotificationTemplateSearch,
} from 'dto/notificationContent';
import {
  NotificationAddressee,
  PostAddresseesSearchParams,
  PostAddresseesSearchResponse,
} from 'dto/api/deviations-management';
import { api } from '@fleet/shared';
import { AddresseeData } from 'routes/SendNotifications/NotificationAddressees';
import qs from 'qs';
import {
  NotificationsTemplate,
  GetNotificationTemplatesResponse,
  NotificationTemplatePreview,
  PostValidateNotificationTemplateBody,
  PostValidateNotificationTemplateResponse,
  PostSendCustomNotificationBody,
} from 'dto/api/notifications-management';

export const setActiveStep = createAction<Step>('notifications/setActiveStep');

export const updateNotificationLanguages = createAction<{
  idx: number;
  checked: boolean;
}>('notifications/updateLanguages');

export const setNotificationFilters = createAction<
  Partial<PostAddresseesSearchParams>
>('notifications/setFilters');

export const getNotificationAddressees = createAsyncThunk<
  Array<NotificationAddressee>,
  PostAddresseesSearchParams
>(
  'notifications/getAddressees',
  async (payload) =>
    (
      await api.post<PostAddresseesSearchResponse>(
        '/notifications/addressees/search',
        { ...payload, limit: 9999 }
      )
    ).data.items
);

export const setNotificationAddressees = createAction<Array<AddresseeData>>(
  'notifications/setAddressees'
);

export const getNotificationTemplatesList = createAsyncThunk<
  Array<NotificationTemplatePreview>,
  NotificationTemplateSearch
>('notifications/getTemplatesList', async (filter) => {
  if (!filter.languages?.length) {
    return (
      (
        await api.get<GetNotificationTemplatesResponse>(
          `/notification-templates${qs.stringify(
            {
              ...filter,
              // organizationId: currentBusinessEntityId TODO: uncomment when ready
              // typeId: 'NOTIFICATION_TEMPLATE_TYPE.CUSTOM'
            },
            {
              addQueryPrefix: true,
              skipNulls: true,
            }
          )}`,
          {
            baseURL: process.env.REACT_APP_API_NM,
          }
        )
      ).data.items || []
    );
  }

  const results = await Promise.all(
    filter.languages.map((languageId) => {
      return api.get<GetNotificationTemplatesResponse>(
        `/notification-templates${qs.stringify(
          {
            // organizationId: currentBusinessEntityId, TODO: uncomment when ready
            // typeId: 'NOTIFICATION_TEMPLATE_TYPE.CUSTOM',
            name: filter.name,
            languageId,
          },
          {
            addQueryPrefix: true,
            skipNulls: true,
          }
        )}`,
        {
          baseURL: process.env.REACT_APP_API_NM,
        }
      );
    })
  );

  const flattenedResults: NotificationTemplatePreview[] = results.flatMap(
    (result) => result?.data?.items ?? []
  );
  const resultsMap = new Map(flattenedResults.map((obj) => [obj.id, obj]));

  return Array.from(resultsMap.values());
});

export const setNotificationTemplateFilter =
  createAction<NotificationTemplateSearch>('notifications/setTemplateFilter');
export const getNotificationTemplateById = createAsyncThunk<
  NotificationTemplatePreview,
  string
>('notifications/getTemplateById', async (templateId) => {
  return (
    await api.get<NotificationsTemplate>(
      `/notification-templates/${templateId}`,
      {
        baseURL: process.env.REACT_APP_API_NM,
      }
    )
  ).data;
});

export const setNotificationLanguagesData = createAction<
  Array<NotificationLanguage>
>('notifications/setLanguages');

export const getValidatedNotifications = createAsyncThunk<
  PostValidateNotificationTemplateResponse,
  PostValidateNotificationTemplateBody
>('notifications/getValidatedNotifications', async (payload) => {
  return (
    await api.post<PostValidateNotificationTemplateResponse>(
      '/notification-templates/validate',
      payload,
      {
        baseURL: process.env.REACT_APP_API_NM,
      }
    )
  ).data;
});

export const sendCustomNotifications = createAsyncThunk<
  void,
  PostSendCustomNotificationBody
>('notifications/sendCustomNotifications', async (payload) => {
  await api.post('/notifications/send', payload, {
    baseURL: process.env.REACT_APP_API_NM,
  });

  // TODO: map payload and set summary data (we already do this in Mediums.tsx, not sure if we can just dispatch those values, needs validating)
});

export const setMappedNotificationContentFormValues =
  createAction<MappedNotificationContentFormValues>(
    'notifications/setContentFormValues'
  );
