import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  addon: <Trans i18nKey="table.head.addon" defaults="Add-on" />,
  admissionNumber: (
    <Trans i18nKey="table.head.admissionNumber" defaults="Admission number" />
  ),
  arrival: <Trans i18nKey="table.head.arrival" defaults="Arrival" />,
  assignOrigin: (
    <Trans i18nKey="table.head.assignOrigin" defaults="Assign origin" />
  ),
  assignDestination: (
    <Trans
      i18nKey="table.head.assignDestination"
      defaults="Assign destination"
    />
  ),
  availability: (
    <Trans i18nKey="table.head.availability" defaults="Availability" />
  ),
  bookingNumber: (
    <Trans i18nKey="table.head.bookingNumber" defaults="Booking number" />
  ),
  carrier: <Trans i18nKey="table.head.carrier" defaults="Carrier" />,
  city: <Trans i18nKey="table.head.city" defaults="City" />,
  coach: <Trans i18nKey="table.head.coach" defaults="Coach" />,
  code: <Trans i18nKey="table.head.code" defaults="Code" />,
  comfortLevel: (
    <Trans i18nKey="table.head.comfortLevel" defaults="Comfort level" />
  ),
  country: <Trans i18nKey="table.head.country" defaults="Country" />,
  county: <Trans i18nKey="table.head.county" defaults="County" />,
  departure: <Trans i18nKey="table.head.departure" defaults="Departure" />,
  departureDateTime: (
    <Trans
      i18nKey="table.head.departureDateTime"
      defaults="Departure datetime"
    />
  ),
  departureStop: (
    <Trans
      i18nKey="table.head.departureStop"
      defaults="Departure stop / zone"
    />
  ),
  departureTime: (
    <Trans i18nKey="table.head.departureTime" defaults="Departure time" />
  ),
  description: (
    <Trans i18nKey="table.head.description" defaults="Description" />
  ),
  destinationStop: (
    <Trans i18nKey="field.destinationStop" defaults="Destination stop" />
  ),
  destinationStopZone: (
    <Trans
      i18nKey="field.destinationStopZone"
      defaults="Destination stop / zone"
    />
  ),
  email: <Trans i18nKey="table.head.email" defaults="Email" />,
  emailRecipients: (
    <Trans i18nKey="table.head.emailRecipients" defaults="E-mail recipients:" />
  ),
  entering: <Trans i18nKey="table.head.entering" defaults="Entering" />,
  firstName: <Trans i18nKey="table.head.firstName" defaults="First name" />,
  firstTicketNumber: (
    <Trans
      i18nKey="table.head.firstTicketNumber"
      defaults="Ticket number (1/2)"
    />
  ),
  isActive: <Trans i18nKey="table.head.isActive" defaults="Active" />,
  isChangeable: (
    <Trans i18nKey="table.head.isChangeable" defaults="Changeable" />
  ),
  isRefundable: (
    <Trans i18nKey="table.head.isRefundable" defaults="Refundable" />
  ),
  language: <Trans i18nKey="table.head.language" defaults="Language" />,
  languages: <Trans i18nKey="table.head.languages" defaults="Languages" />,
  leaving: <Trans i18nKey="table.head.leaving" defaults="Leaving" />,
  line: <Trans i18nKey="table.head.line" defaults="Line" />,
  lineName: <Trans i18nKey="table.head.lineName" defaults="Line name" />,
  lineNumber: <Trans i18nKey="table.head.lineNumber" defaults="Line number" />,
  medium: <Trans i18nKey="table.head.medium" defaults="Medium" />,
  name: <Trans i18nKey="table.head.name" defaults="Name" />,
  occupancy: <Trans i18nKey="table.head.occupancy" defaults="Occupancy" />,
  originStop: <Trans i18nKey="table.head.originStop" defaults="Origin stop" />,
  originStopZone: (
    <Trans i18nKey="table.head.originStopZone" defaults="Origin stop / zone" />
  ),
  owner: <Trans i18nKey="table.head.owner" defaults="Owner" />,
  passengerCategory: (
    <Trans
      i18nKey="table.head.passengerCategory"
      defaults="Passenger category"
    />
  ),
  phone: <Trans i18nKey="table.head.phone" defaults="Phone" />,
  price: <Trans i18nKey="table.head.price" defaults="Price" />,
  priority: <Trans i18nKey="table.head.priority" defaults="Priority" />,
  pushNotification: (
    <Trans i18nKey="table.head.pushNotification" defaults="Push notification" />
  ),
  pushNotificationRecipients: (
    <Trans
      i18nKey="table.head.pushNotificationRecipients"
      defaults="Push notification recipients:"
    />
  ),
  pushNotificationsEnabled: (
    <Trans
      i18nKey="table.head.pushNotificationsEnabled"
      defaults="Push notifications enabled"
    />
  ),
  quantity: <Trans i18nKey="table.head.quantity" defaults="Quantity" />,
  seat: <Trans i18nKey="table.head.seat" defaults="Seat" />,
  secondTicketNumber: (
    <Trans
      i18nKey="table.head.secondTicketNumber"
      defaults="Ticket number (2/2)"
    />
  ),
  sendEvenWhenHigherPriorityExists: (
    <Trans
      i18nKey="table.head.sendEvenWhenHigherPriorityExists"
      defaults="Use even if higher priority channel is used"
    />
  ),
  serviceCode: (
    <Trans i18nKey="table.head.serviceCode" defaults="Service code" />
  ),
  shortArrivalTime: (
    <Trans i18nKey="table.head.shortArrivalTime" defaults="Arr." />
  ),
  shortDepartureTime: (
    <Trans i18nKey="table.head.shortDepartureTime" defaults="Dep." />
  ),
  smsRecipients: (
    <Trans i18nKey="table.head.smsRecipients" defaults="SMS recipients:" />
  ),
  status: <Trans i18nKey="table.head.status" defaults="Status" />,
  stop: <Trans i18nKey="table.head.stop" defaults="Stop" />,
  stopsFromTo: (
    <Trans i18nKey="table.head.stopsFromTo" defaults="Stops (from-to)" />
  ),
  streetHouseNumber: (
    <Trans
      i18nKey="table.head.streetHouseNumber"
      defaults="Street, house, number"
    />
  ),
  surname: <Trans i18nKey="table.head.surname" defaults="Surname" />,
  trip: <Trans i18nKey="table.head.trip" defaults="Trip" />,
  tripName: <Trans i18nKey="table.head.tripName" defaults="Trip name" />,
  type: <Trans i18nKey="table.head.type" defaults="Type" />,
  useFallbackLanguage: (
    <Trans
      i18nKey="table.head.useFallbackLanguage"
      defaults="Use fallback language"
    />
  ),
  vehicleCarriage: (
    <Trans i18nKey="table.head.vehicleCarriage" defaults="Vehicle / Carriage" />
  ),
  vehicleProfile: (
    <Trans i18nKey="table.head.vehicleProfile" defaults="Vehicle profile" />
  ),
});
