import {
  Button,
  FormProvider,
  Loadable,
  Modal,
  SelectField,
  Table,
  TableColumns,
  useForm,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useRowSelect, useTable } from 'react-table';
import { Icon } from '@fleet/shared/mui';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { fetchAncillaryTypes } from 'features/notifications/notificationsService';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import type { Classifier } from '@fleet/shared/dto/classifier';

interface TripsSelectionModalProps {
  isOpen: boolean;
  onSubmit: (value: Array<Classifier>) => void;
  onClose: () => void;
}

interface AncillaryRow extends Classifier {
  description: string;
  code: string;
  orderNumber: number | null;
}

interface AncillariesSearchParams {
  carrierId: string;
}

export const AncillariesModal: FC<TripsSelectionModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<AncillaryRow[]>([]);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const carrierOptions = useClassificationOptions(ClassificationGroup.CARRIER);

  const columns = useMemo<TableColumns<AncillaryRow>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="description" />,
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
      },
      {
        accessor: 'orderNumber',
        Header: <TransTableHead i18nKey="isActive" />,
      },
    ],
    []
  );

  const table = useTable<AncillaryRow>(
    {
      data,
      columns,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const handleSearchSubmit = useCallback(async (filter) => {
    setIsLoading(true);
    const values = await fetchAncillaryTypes(filter);
    const mappedValues: AncillaryRow[] = values.map((ancillary) => ({
      id: ancillary.id,
      name: ancillary.name,
      description: ancillary.description,
      code: ancillary.code,
      orderNumber: ancillary.orderNumber ?? null,
    }));
    setData(mappedValues);

    setIsLoading(false);
  }, []);

  const { form, handleSubmit, dirty, submitting } =
    useForm<AncillariesSearchParams>({
      onSubmit: handleSearchSubmit,
      subscription: { dirty: true, submitting: true },
    });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={<TransTitle i18nKey="selectAncillaries" />}
      actionButton={
        <Button
          variant="contained"
          type="submit"
          onClick={() => {
            onSubmit(
              table.selectedFlatRows.map((row) => ({
                name: row.original.name,
                id: row.original.id,
              }))
            );
          }}
          startIcon={<Icon name="check" size={20} />}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
      maxWidth="md"
      fullWidth
    >
      <Loadable loading={isLoading}>
        <Table
          caption={
            <>
              <FormProvider {...form}>
                <form onSubmit={handleSubmit}>
                  <Grid container columns={3} spacing={2} alignItems="flex-end">
                    <Grid item xs={1}>
                      <SelectField
                        name="ownerId"
                        label={<TransField i18nKey="carrier" />}
                        options={carrierOptions}
                        defaultValue={currentBusinessEntityId}
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      xs="auto"
                      sx={{ ml: 'auto' }}
                      alignItems="flex-end"
                    >
                      <Stack direction="row" spacing={2}>
                        <Button
                          sx={{ whiteSpace: 'nowrap' }}
                          variant="text"
                          onClick={handleReset}
                        >
                          <TransButton i18nKey="resetFilters" />
                        </Button>

                        <Button
                          variant="contained"
                          type="submit"
                          startIcon={<Icon name="search" size={20} />}
                          disabled={!dirty || submitting}
                        >
                          <TransButton i18nKey="search" />
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
              <Divider sx={{ pt: '24px' }} />
              <CardContent sx={{ pl: '0' }}>
                <Stack
                  direction="row"
                  alignItems="baseline"
                  justifyContent={'space-between'}
                  spacing={1}
                >
                  <Box sx={{ width: '110px' }}>
                    <Typography variant="subtitle" fontWeight={700}>
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <TransSubtitle
                        i18nKey="ancillariesQty"
                        values={{ count: table.selectedFlatRows.length }}
                      />
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </>
          }
          table={table}
        />
      </Loadable>
    </Modal>
  );
};
