import { api } from '@fleet/shared';
import qs from 'qs';
import type {
  GetLinesItem,
  GetLinesResponse,
  GetLinesSearchParams,
  GetTripsItem,
  GetTripsResponse,
} from 'dto/api/line-management';
import type {
  GetInventoryClassesItem,
  GetInventoryClassesSearchParams,
  GetInventoryClassesResponse,
} from 'dto/api/vehicle-management';
import type {
  GetAncillaryTypesItem,
  GetAncillaryTypesResponse,
  GetAncillaryTypesSearchParams,
} from 'dto/product-definitions';

import { FetchTripsSearch } from 'dto/api/line-management';

export const fetchLines = async (
  filter: GetLinesSearchParams
): Promise<GetLinesItem[]> =>
  (
    await api.get<GetLinesResponse>(
      `/lines${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`,
      {
        baseURL: process.env.REACT_APP_API_LM,
      }
    )
  ).data.items;

export const fetchTrips = async (
  filter: FetchTripsSearch,
  currentBusinessEntityId: number
): Promise<Array<GetTripsItem>> =>
  (
    await api.post<GetTripsResponse>(
      `/organizations/${currentBusinessEntityId}/trips/get`,
      {
        ...filter,
        ...(filter.serviceCodes ? { serviceCodes: [filter.serviceCodes] } : {}),
        limit: 9999,
      },
      {
        baseURL: process.env.REACT_APP_API_LM,
      }
    )
  ).data.items;

export const fetchInventoryClasses = async (
  filter: GetInventoryClassesSearchParams,
  currentBusinessEntityId: number
): Promise<Array<GetInventoryClassesItem>> =>
  (
    await api.get<GetInventoryClassesResponse>(
      `/business-entities/${currentBusinessEntityId}/inventory-classes${qs.stringify(
        filter,
        {
          addQueryPrefix: true,
          skipNulls: true,
        }
      )}`,
      {
        baseURL: process.env.REACT_APP_API_VM,
      }
    )
  ).data.items;

export const fetchAncillaryTypes = async (
  filter: GetAncillaryTypesSearchParams
): Promise<Array<GetAncillaryTypesItem>> =>
  (
    await api.get<GetAncillaryTypesResponse>(
      `/ancillary-types${qs.stringify(filter, {
        skipNulls: true,
        addQueryPrefix: true,
      })}`,
      { baseURL: process.env.REACT_APP_API_PM }
    )
  ).data.items;
