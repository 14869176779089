import React from 'react';
import { Grid } from '@mui/material';
import { PeriodField, SelectField, TextField, TimeUnit } from '@fleet/shared';
import { ModalTextfieldArray } from './ModalTextfieldArray';
import { TransLabel } from 'i18n/trans/label';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { selectCurrentBusinessEntityId } from 'features/common/commonSelectors';
import { TransField } from 'i18n/trans/field';

export const NotificationFiltersFields = () => {
  const carriersOptions = useClassificationOptions(ClassificationGroup.CARRIER);
  const currentBusinessEntityId = useSelector(selectCurrentBusinessEntityId);
  const seatPropertyOptions = useClassificationOptions(
    ClassificationGroup.SEAT_PROPERTY
  );
  const compartmentPropertyOptions = useClassificationOptions(
    ClassificationGroup.COMPARTMENT_PROPERTY
  );
  const bedPropertyOptions = useClassificationOptions(
    ClassificationGroup.BED_PROPERTY
  );

  const placePropertyOptions = [
    ...seatPropertyOptions,
    ...compartmentPropertyOptions,
    ...bedPropertyOptions,
  ];
  return (
    <Grid container columns={1} spacing={1}>
      <Grid item xs={1}>
        <SelectField
          label={<TransLabel i18nKey={'carrier'} />}
          name="carrierId"
          options={carriersOptions}
          defaultValue={currentBusinessEntityId}
          disabled
        />
      </Grid>
      <Grid item xs={1}>
        <ModalTextfieldArray
          label={<TransLabel i18nKey={'lines'} />}
          name={'lineIds'}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          label={<TransLabel i18nKey={'service'} />}
          name={'serviceCodes'}
        />
      </Grid>
      <Grid item xs={1}>
        <PeriodField
          from={{
            name: 'departureFrom',
            label: <TransField i18nKey="departureFrom" />,
          }}
          to={{
            name: 'departureTo',
            label: <TransField i18nKey="departureTo" />,
          }}
          maxRange={{ [TimeUnit.DAYS]: 60 }}
        />
      </Grid>
      <Grid item xs={1}>
        <ModalTextfieldArray
          label={<TransLabel i18nKey={'trips'} />}
          name={'tripIds'}
        />
      </Grid>
      <Grid item xs={1}>
        <ModalTextfieldArray
          label={<TransLabel i18nKey={'fromStop'} />}
          name={'fromStopIds'}
        />
      </Grid>
      <Grid item xs={1}>
        <ModalTextfieldArray
          label={<TransLabel i18nKey={'toStop'} />}
          name={'toStopIds'}
        />
      </Grid>
      <Grid item xs={1}>
        {/* TODO: replace with dropdown */}
        <ModalTextfieldArray
          label={<TransLabel i18nKey={'inventoryClasses'} />}
          name={'inventoryClassIds'}
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          label={<TransLabel i18nKey={'placeProperties'} />}
          name={'placePropertiesIds'}
          options={placePropertyOptions}
          multiple
          showEmptyOption
        />
      </Grid>
      <Grid item xs={1}>
        <ModalTextfieldArray
          label={<TransLabel i18nKey={'ancillaries'} />}
          name={'ancillaries'}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="firstName"
          label={<TransLabel i18nKey="firstName" />}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField name="lastName" label={<TransLabel i18nKey="lastName" />} />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="bookingNumber"
          label={<TransLabel i18nKey="bookingNumber" />}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="ticketNumber"
          label={<TransLabel i18nKey="ticketNumber" />}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="travelCardNumber"
          label={<TransLabel i18nKey="travelCardNumber" />}
        />
      </Grid>
    </Grid>
  );
};
