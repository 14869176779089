import type { FC } from 'react';
import React, { useMemo } from 'react';
import {
  Button,
  Loadable,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { useRowSelect, useTable } from 'react-table';
import { Box, CardContent, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import {
  setActiveStep,
  setNotificationAddressees,
} from 'features/notifications/notificationsActions';
import { Step } from 'dto/stepper';
import { notificationFilterListSelector } from 'features/notifications/notificationsSelectors';
import { Nullable } from 'helpers/types';
import { getAddresseesSummaryCounts } from 'helpers/notificationAddressees';

interface NotificationAddresseesProps {}

export interface AddresseeRow {
  email: Nullable<string>;
  phone?: Nullable<string>;
  language?: Nullable<string>;
  tripName?: Nullable<string>;
  originStopZone: string;
  destinationStopZone: string;
}

export interface AddresseePayload {
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  languageId: string;
  bookingId: string;
  legId: Nullable<number>;
  email: Nullable<string>;
  phoneNumber: Nullable<string>;
  phoneAreaCode: Nullable<string>;
  customerProfileId: Nullable<string>;
}

export interface AddresseeData extends AddresseeRow, AddresseePayload {}

export const NotificationAddressees: FC<NotificationAddresseesProps> = () => {
  const dispatch = useDispatch();
  const columns = useMemo<TableColumns<AddresseeData>>(
    () => [
      {
        accessor: 'email',
        Header: <TransTableHead i18nKey="email" />,
        width: '120px',
      },
      {
        accessor: 'phone',
        Header: <TransTableHead i18nKey="phone" />,
        width: '120px',
      },
      {
        accessor: 'language',
        Header: <TransTableHead i18nKey="language" />,
        width: '120px',
      },
      {
        accessor: 'tripName',
        Header: <TransTableHead i18nKey="tripName" />,
        width: '120px',
      },
      {
        accessor: 'originStopZone',
        Header: <TransTableHead i18nKey="originStopZone" />,
        width: '120px',
      },
      {
        accessor: 'destinationStopZone',
        Header: <TransTableHead i18nKey="destinationStopZone" />,
        width: '120px',
      },
    ],
    []
  );

  const addressees = useSelector(notificationFilterListSelector);
  const data: Array<AddresseeData> = useMemo(
    () =>
      addressees.map(({ addressee, trip, bookingId, legId }) => ({
        email: addressee.email,
        phone: addressee.phoneNumber,
        language: addressee.culture.name,
        tripName: trip.name,
        originStopZone: trip.originStopName,
        destinationStopZone: trip.destinationStopName,
        firstName: addressee.firstName,
        lastName: addressee.lastName,
        languageId: addressee.culture.id,
        bookingId,
        legId,
        phoneNumber: addressee.phoneNumber,
        phoneAreaCode: addressee.phoneAreaCode,
        customerProfileId: addressee.customerProfileId,
      })),
    [addressees]
  );

  const table = useTable<AddresseeData>(
    {
      data,
      columns,
      total: data.length,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const handleSubmit = () => {
    const chosenPassengers = table.selectedFlatRows.map((row) => row.original);

    dispatch(setNotificationAddressees(chosenPassengers));
    dispatch(setActiveStep(Step.Content));
  };
  const selectedAddresseesCount = table.selectedFlatRows.length;

  const {
    potentialAddresseesCount,
    potentialLanguagesCount,
    emailsCount,
    phoneNumbersCount,
  } = getAddresseesSummaryCounts(addressees);

  return (
    <Loadable loading={false}>
      <Table
        caption={
          <CardContent sx={{ p: 2 }}>
            <Stack direction="row" alignItems="baseline" spacing={1}>
              <Typography variant="subtitle" fontWeight={700}>
                <TransSubtitle i18nKey="assignees" />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <TransSubtitle
                  i18nKey="potentialAddresseesQty"
                  values={{ count: potentialAddresseesCount }}
                />
                {', '}
                <TransSubtitle
                  i18nKey="selectedLanguages"
                  values={{ count: potentialLanguagesCount }}
                />
                {', '}
                <TransSubtitle
                  i18nKey="emailsQty"
                  values={{ count: emailsCount }}
                />
                {', '}
                <TransSubtitle
                  i18nKey="phoneNumbersQty"
                  values={{ count: phoneNumbersCount }}
                />
              </Typography>
            </Stack>
          </CardContent>
        }
        table={table}
      ></Table>
      <Stack direction="row" sx={{ py: 2, px: 3 }}>
        <Typography fontWeight={400}>
          <TransSubtitle
            i18nKey="selectedAddressees"
            values={{ count: selectedAddresseesCount }}
          />
        </Typography>
        <Box sx={{ flex: 1 }}></Box>
        <Stack direction="row">
          <Button
            onClick={() => {
              table.toggleAllRowsSelected(false);
            }}
            variant="text"
          >
            <TransButton i18nKey="reset" />
          </Button>
          <Button
            icon="arrow-right"
            onClick={() => handleSubmit()}
            sx={{ ml: 2 }}
          >
            <TransButton i18nKey="notificationContent" />
          </Button>
        </Stack>
      </Stack>
    </Loadable>
  );
};
