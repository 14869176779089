import React, { FC, useMemo, useState } from 'react';
import { Icon, Table, TableColumns } from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { Row, useTable } from 'react-table';
import { Radio, Stack, Typography } from '@mui/material';
import { Button } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { notificationTemplatesListSelector } from 'features/notifications/notificationsSelectors';
import { NotificationTemplatePreview } from 'dto/api/notifications-management';
import { getNotificationTemplateById } from 'features/notifications/notificationsActions';

interface UseTemplateTableProps {}

export const UseTemplateTable: FC<UseTemplateTableProps> = () => {
  const dispatch = useDispatch();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();

  const data: Array<NotificationTemplatePreview> =
    useSelector(notificationTemplatesListSelector) || [];

  const columns = useMemo<TableColumns<NotificationTemplatePreview>>(
    () => [
      {
        id: 'selection',
        width: 40,
        Cell: ({ row }: { row: Row<NotificationTemplatePreview> }) => (
          <Radio
            onClick={() => setSelectedTemplateId(row.original.id!)}
            size="small"
            checked={row.original.id === selectedTemplateId}
          />
        ),
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        width: '120px',
      },
      {
        id: 'organization.name',
        accessor: ({ organization }) => organization?.name || '-',
        Header: <TransTableHead i18nKey="owner" />,
        width: '120px',
      },
      {
        id: 'type.name',
        accessor: ({ type }) => type?.name || '-',
        Header: <TransTableHead i18nKey="type" />,
        width: '120px',
      },
      {
        id: 'languages',
        accessor: ({ languages }) =>
          languages?.map((lang) => lang.name).join(', ') || '-',
        Header: <TransTableHead i18nKey="languages" />,
        width: '120px',
      },
      {
        accessor: 'hasEmailContent',
        Header: <TransTableHead i18nKey="email" />,
        width: '120px',
        Cell: ({
          row: {
            original: { hasEmailContent },
          },
        }) => renderCheckmarkIfTrue(hasEmailContent),
      },
      {
        accessor: 'hasSmsContent',
        Header: 'SMS',
        Cell: ({
          row: {
            original: { hasSmsContent },
          },
        }) => renderCheckmarkIfTrue(hasSmsContent),
      },
      {
        accessor: 'hasPushContent',
        Header: <TransTableHead i18nKey="pushNotification" />,
        Cell: ({
          row: {
            original: { hasPushContent },
          },
        }) => renderCheckmarkIfTrue(hasPushContent),
      },
    ],
    [selectedTemplateId]
  );

  const table = useTable({ data, columns });
  const handleTemplateSubmit = () => {
    dispatch(getNotificationTemplateById(selectedTemplateId!));
  };

  return (
    <Stack direction="column">
      <Table table={table} variant="scrollable" sx={{ borderWidth: 1 }} />
      <Button
        icon="edit"
        disabled={!selectedTemplateId}
        onClick={() => handleTemplateSubmit()}
        sx={{ width: '161px', mt: 2 }}
      >
        <Typography sx={{ minWidth: 'fit-content' }}>
          <TransButton i18nKey="fillFromTemplate" />
        </Typography>
      </Button>
    </Stack>
  );
};
const renderCheckmarkIfTrue = (condition: boolean | undefined) => {
  // TODO: Move to helper function file and use it in other tables aswell
  if (condition) {
    return <Icon name="check" color="success" />;
  }
  return <></>;
};
