import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import { Passenger } from 'dto/passengers';
import {
  ReAccommodateTripsFilter,
  PostReAccommodateTripsResponse,
  PostReAccommodateStartResponse,
  GetReAccommodateDetailedTripResponse,
  PassengerReAccommodateStep,
  PostReAccommodateValidateResponse,
  PostReAccommodateValidateRequest,
  PostReAccommodateRequestNewTrip,
} from 'dto/reAccommodate';
import omit from 'lodash/omit';
import { api, formatDate, isoDateTimeFormat } from '@fleet/shared';

export const resetAccommodate = createAction('reAccommodate/resetAccommodate');

export const setReAccommodateCurrentTripsList = createAction<Array<Passenger>>(
  'reAccommodate/setReAccommodateCurrentTripsList'
);

export const setReAccommodateActiveStep =
  createAction<PassengerReAccommodateStep>(
    'reAccommodate/setReAccommodateActiveStep'
  );

export const setReAccommodateNewTripsFilter = createAction<
  Partial<ReAccommodateTripsFilter>
>('reAccommodate/setReAccommodateNewTripsFilter');

export const getReAccommodateNewTripsList = createAsyncThunk<
  PostReAccommodateTripsResponse,
  Partial<ReAccommodateTripsFilter>
>(
  'reAccommodate/getReAccommodateNewTripsList',
  async (values, { getState, dispatch }) => {
    if (values) {
      dispatch(setReAccommodateNewTripsFilter(values));
    }

    const {
      reAccommodate: {
        newTripsFilter: { carrierId, ...newTripsFilter },
      },
    } = getState();

    const [dateFormat] = isoDateTimeFormat.split("'T'");
    const filter = {
      ...omit(newTripsFilter, ['departurePeriod', 'stopOne', 'stopTwo']),
      tripDateFrom:
        newTripsFilter.departurePeriod?.from &&
        formatDate(newTripsFilter.departurePeriod.from, dateFormat),
      tripDateTo:
        newTripsFilter.departurePeriod?.to &&
        formatDate(newTripsFilter.departurePeriod.to, dateFormat),
      stopOneId: newTripsFilter.stopOne?.id,
      stopTwoId: newTripsFilter.stopTwo?.id,
    };

    return (
      await api.post<PostReAccommodateTripsResponse>(
        `/organizations/${carrierId}/trips/get`,
        filter,
        {
          baseURL: process.env.REACT_APP_API_LM,
        }
      )
    ).data;
  }
);

export const getReAccommodateNewDetailedTrip = createAsyncThunk<
  GetReAccommodateDetailedTripResponse,
  number
>(
  'reAccommodate/getReAccommodateNewDetailedTrip',
  async (tripId, { getState }) => {
    const {
      reAccommodate: { newDetailedTrips },
    } = getState();

    if (newDetailedTrips[tripId]) {
      return newDetailedTrips[tripId];
    }

    return (
      await api.get<GetReAccommodateDetailedTripResponse>(
        `/trips/${tripId}/route-details`,
        {
          baseURL: process.env.REACT_APP_API_LM,
        }
      )
    ).data;
  }
);

const getValuesForReAccommodate = (
  currentTripsList: Array<Passenger>,
  {
    newTripId,
    newOriginRouteId,
    newDestinationRouteId,
  }: PostReAccommodateRequestNewTrip
): PostReAccommodateValidateRequest => ({
  newTripId,
  admissions: currentTripsList.map(
    ({ admissionNumber, trip: { id: oldTripId } }) => ({
      admissionNumber,
      oldTripId,
      newOriginRouteId,
      newDestinationRouteId,
    })
  ),
});

export const startReAccommodateCurrentProcess = createAsyncThunk<
  PostReAccommodateStartResponse,
  PostReAccommodateRequestNewTrip
>(
  'reAccommodate/startReAccommodateCurrentProcess',
  async (newTrip, { getState }) => {
    const {
      reAccommodate: { currentTripsList },
    } = getState();

    const values = getValuesForReAccommodate(currentTripsList, newTrip);

    return (
      await api.post<PostReAccommodateStartResponse>(
        '/re-allocations/start',
        values
      )
    ).data;
  }
);

export const validateReAccommodateCurrentProcess = createAsyncThunk<
  PostReAccommodateValidateResponse,
  PostReAccommodateRequestNewTrip
>(
  'reAccommodate/validateReAccommodateCurrentProcess',
  async (newTrip, { getState }) => {
    const {
      reAccommodate: { currentTripsList },
    } = getState();

    const values = getValuesForReAccommodate(currentTripsList, newTrip);

    return (
      await api.post<PostReAccommodateValidateResponse>(
        '/re-allocations/validate',
        values
      )
    ).data;
  }
);
