import React, { FC, useEffect } from 'react';
import { Icon, TabPanel, Tabs, useFormContext } from '@fleet/shared';
import { EmailTab } from 'routes/SendNotifications/content/sections/email/EmailTab';
import { Typography } from '@mui/material';
import { EnrichedMediumDataEmail } from 'dto/mediums';
import { notificationLanguagesSelector } from 'features/notifications/notificationsSelectors';
import { useSelector } from 'store/utils';

interface EmailTabsProps {
  tabData: Array<EnrichedMediumDataEmail>;
}

export const EmailTabs: FC<EmailTabsProps> = ({ tabData }) => {
  const notificationLanguages = useSelector(notificationLanguagesSelector);
  const form = useFormContext();
  const doesntUseChosenFallbackLanguage = (data: EnrichedMediumDataEmail) =>
    !data.useFallbackLanguage;
  const hasRecipients = (tab: EnrichedMediumDataEmail): boolean => {
    return Boolean(
      notificationLanguages?.find((lang) => lang.languageId === tab.language.id)
        ?.emailRecipients
    );
  };
  const hasNecessaryContent = ({
    emailContent,
    emailSubject,
    emailSenderEmail,
    emailSenderName,
  }: EnrichedMediumDataEmail) =>
    emailContent && emailSubject && emailSenderEmail && emailSenderName;

  useEffect(() => {
    tabData
      .filter(hasRecipients)
      .filter(doesntUseChosenFallbackLanguage)
      .forEach((tab, i) => {
        Object.keys(tab).forEach((key) => {
          form.change(
            `notificationsByLanguage[${i}].${key}`,
            tab[key as keyof EnrichedMediumDataEmail]
          );
        });
      });
  });
  return (
    <Tabs value={tabData[0].language.id}>
      {tabData
        .filter(hasRecipients)
        .filter(doesntUseChosenFallbackLanguage)
        .map((tab, i) => {
          return (
            <TabPanel
              value={tab.language.id}
              key={tab.language.id}
              label={
                <Typography>
                  {tab.language.name}
                  &nbsp;
                  {hasNecessaryContent(tab) ? (
                    <Icon name={'check-circle'} color="success" />
                  ) : (
                    <Icon name={'error-circle'} color="error" />
                  )}
                </Typography>
              }
            >
              <EmailTab index={i} />
            </TabPanel>
          );
        })}
    </Tabs>
  );
};
