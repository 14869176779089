import React, { FC, useCallback } from 'react';
import { SearchForm } from '@fleet/shared';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { UseTemplateTable } from './UseTemplateTable';
import {
  getNotificationTemplatesList,
  setNotificationTemplateFilter,
} from 'features/notifications/notificationsActions';
import { useDispatch } from 'store/utils';
import { NotificationTemplateSearch } from 'dto/notificationContent';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
interface UseTemplateProps {}

export const UseTemplate: FC<UseTemplateProps> = () => {
  const dispatch = useDispatch();
  const languages = useClassificationOptions(ClassificationGroup.CULTURE);

  const onSubmit = useCallback(
    (filter: NotificationTemplateSearch) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        dispatch(setNotificationTemplateFilter(filter));
        await dispatch(getNotificationTemplatesList(filter));
      }),
    [dispatch]
  );

  const { form, handleSubmit } = useForm<NotificationTemplateSearch>({
    onSubmit,
  });

  const handleReset = () => {
    form.reset();
  };

  return (
    <SearchForm title={<TransSubtitle i18nKey="useTemplate" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={4} spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={1}>
              <TextField name="Name" label={<TransField i18nKey="name" />} />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="languages"
                label={<TransField i18nKey="languages" />}
                options={languages}
                multiple
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button onClick={handleReset} variant="text">
                    <TransButton i18nKey="reset" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button icon="search" type="submit" sx={{ ml: 2 }}>
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <UseTemplateTable />
    </SearchForm>
  );
};
