import { createReducer } from '@reduxjs/toolkit';
import {
  PassengerSearchGroup,
  PostPassengersByTripsResponse,
  PassengerFilterByTrips,
} from 'dto/passengers';
import {
  getPassengersByTrips,
  setPassengersFilterByTrips,
  setPassengersFilterTab,
} from 'features/passengers/passengersActions';

export interface CommonState {
  filterByTrips: Partial<PassengerFilterByTrips>;
  listByTrips?: PostPassengersByTripsResponse;
  tab: PassengerSearchGroup;
}

const initialState: CommonState = {
  filterByTrips: {},
  tab: PassengerSearchGroup.TRIPS,
};

export const passengersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPassengersFilterTab, (state, action) => {
      state.tab = action.payload;
    })
    .addCase(setPassengersFilterByTrips, (state, action) => {
      state.filterByTrips = action.payload;
    })
    .addCase(getPassengersByTrips.fulfilled, (state, action) => {
      state.listByTrips = action.payload;
    });
});
