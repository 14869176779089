import { createReducer } from '@reduxjs/toolkit';
import {
  getNotificationAddressees,
  getNotificationTemplatesList,
  setActiveStep,
  setNotificationAddressees,
  setNotificationFilters,
  getNotificationTemplateById,
  setNotificationTemplateFilter,
  updateNotificationLanguages,
  setNotificationLanguagesData,
  getValidatedNotifications,
  setMappedNotificationContentFormValues,
} from './notificationsActions';
import { Step } from 'dto/stepper';
import {
  RecipientsCountByLanguageId,
  NotificationContent,
} from 'dto/notificationContent';
import {
  NotificationAddressee,
  PostAddresseesSearchParams,
} from 'dto/api/deviations-management';
import { AddresseeData } from 'routes/SendNotifications/NotificationAddressees';
import { defaultNotificationChannelData } from 'helpers/consts';

export interface CommonState {
  notificationFilters: {
    filter: Partial<PostAddresseesSearchParams>;
    list: Array<NotificationAddressee>;
  };
  notificationAddressees: {
    list: Array<AddresseeData>;
  };
  notificationContent: NotificationContent;
  notificationSummary: {
    emailNotificationsCount: number;
    smsNotificationsCount: number;
    pushNotificationsCount: number;
  };
  activeStep: Step;
  addresseesSummary?: RecipientsCountByLanguageId;
}

const initialState: CommonState = {
  notificationFilters: {
    filter: {},
    list: [],
  },
  notificationAddressees: {
    list: [],
  },
  notificationContent: {
    templates: {},
    notificationChannels: {
      list: defaultNotificationChannelData,
    },
    notificationLanguages: {},
    sms: {},
    email: {},
    pushNotification: {},
    validation: {
      list: [],
    },
  },
  notificationSummary: {
    emailNotificationsCount: 0,
    smsNotificationsCount: 0,
    pushNotificationsCount: 0,
  },
  activeStep: Step.Filters,
};

export const notificationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setActiveStep, (state, action) => {
      state.activeStep = action.payload;
    })
    .addCase(updateNotificationLanguages, (state, action) => {
      const { checked, idx } = action.payload;
      const languages = state.notificationContent.notificationLanguages.list;
      if (!languages) {
        return;
      }

      languages[idx] = {
        ...languages[idx],
        useFallbackLanguage: checked,
      };

      state.notificationContent.notificationLanguages.list = languages;
    })
    .addCase(setNotificationFilters, (state, action) => {
      state.notificationFilters.filter = action.payload;
    })
    .addCase(getNotificationAddressees.fulfilled, (state, action) => {
      state.notificationFilters.list = action.payload;
    })
    .addCase(setNotificationAddressees, (state, action) => {
      state.notificationAddressees.list = action.payload;
    })
    .addCase(getNotificationTemplatesList.fulfilled, (state, action) => {
      state.notificationContent.templates.list = action.payload;
    })
    .addCase(setNotificationTemplateFilter, (state, action) => {
      state.notificationContent.templates.filter = action.payload;
    })
    .addCase(getNotificationTemplateById.fulfilled, (state, action) => {
      state.notificationContent.templates.current = action.payload;
    })
    .addCase(setNotificationLanguagesData, (state, action) => {
      state.notificationContent.notificationLanguages.list = action.payload;
    })
    .addCase(getValidatedNotifications.fulfilled, (state, action) => {
      state.notificationContent.validation.list =
        action.payload.notificationsByLanguage || [];
    })
    .addCase(setMappedNotificationContentFormValues, (state, action) => {
      state.notificationContent.validation.formValues = action.payload;
    });
});
