import type { Classifier } from '@fleet/shared/dto/classifier';

export interface ClassificationGroupItem {
  id: ClassificationKey;
  classifications: Array<Classifier>;
}

export enum ClassificationGroup {
  CURRENCY = 'CURRENCY',
  TRIP_STATUS = 'TRIP_STATUS',
  SEAT_PROPERTY = 'SEAT_PROPERTY',
  COMPARTMENT_PROPERTY = 'COMPARTMENT_PROPERTY',
  BED_PROPERTY = 'BED_PROPERTY',
  CULTURE = 'CULTURE',
  NOTIFICATION_TEMPLATE_TYPE = 'NOTIFICATION_TEMPLATE_TYPE',
  NOTIFICATION_CHANNEL = 'NOTIFICATION_CHANNEL',

  // !ClassificationGroups
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  CARRIER = 'CARRIER',
  COUNTRY = 'COUNTRY',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.CURRENCY,
  ClassificationGroup.TRIP_STATUS,
  ClassificationGroup.SEAT_PROPERTY,
  ClassificationGroup.COMPARTMENT_PROPERTY,
  ClassificationGroup.BED_PROPERTY,
  ClassificationGroup.CULTURE,
  ClassificationGroup.NOTIFICATION_TEMPLATE_TYPE,
  ClassificationGroup.NOTIFICATION_CHANNEL,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  typeof STANDARD_CLASSIFICATION_GROUPS[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;

export interface BusinessEntity extends Classifier<number> {
  isMain: boolean;
  contactType: string;
}
