import type { FC } from 'react';
import { useCallback } from 'react';
import { Button, FormProvider, formSubmit, useForm } from '@fleet/shared';
import { Box, Stack } from '@mui/material';
import { NotificationFiltersFields } from './NotificationFiltersFields';
import { TransButton } from 'i18n/trans/button';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'store/utils';
import {
  getNotificationAddressees,
  setActiveStep,
  setNotificationFilters,
} from 'features/notifications/notificationsActions';
import { Step } from 'dto/stepper';
import { PostAddresseesSearchParams } from 'dto/api/deviations-management';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

interface NotificationFiltersProps {}

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    height: '-webkit-fill-available',
  },
}));

export const NotificationFilters: FC<NotificationFiltersProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();

  const onSubmit = useCallback(
    // Remove serviceCodes overwrite once endpoint fixed
    async (
      values: Omit<PostAddresseesSearchParams, 'serviceCodes'> & {
        serviceCodes?: string;
      }
    ) => {
      const mappedValues = {
        ...values,
        serviceCodes: values.serviceCodes ? [values.serviceCodes] : [], // endpoint expects array, should expect string value
        limit: 9999,
        offset: 0,
      };

      const { departureFrom, departureTo, tripIds } = mappedValues;
      const departureTimesOrTripsFilled =
        (departureFrom && departureTo) || tripIds;

      if (departureTimesOrTripsFilled) {
        dispatch(setNotificationFilters(mappedValues));

        formSubmit(async () => {
          (document.activeElement as HTMLInputElement)?.blur?.();
          await dispatch(getNotificationAddressees(mappedValues));
        });

        dispatch(setActiveStep(Step.Addressees));
      } else {
        alert.error(<TransAlert i18nKey="departureTimesOrTripsFilled" />);
      }
    },
    [dispatch, alert]
  );

  const { form, handleSubmit } = useForm({
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <FormProvider {...form}>
      {/* TODO: Get rid of the classes.paper */}
      <form onSubmit={handleSubmit} className={classes.paper}>
        <Box sx={{ p: 2, m: 'auto', width: '800px' }}>
          <NotificationFiltersFields />
        </Box>

        <Stack
          direction="row"
          sx={{ ml: 'auto', mr: 4, mt: 3, mb: 2, justifyContent: 'end' }}
          spacing={1}
        >
          <Button onClick={handleReset} variant="text">
            <TransButton i18nKey="reset" />
          </Button>
          <Button icon="arrow-right" type="submit" sx={{ ml: 2 }}>
            {/* TODO: Button disabled until either Departure Times or Trips filled */}
            <TransButton i18nKey="notificationAddressees" />
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
