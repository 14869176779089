import { SearchForm } from '@fleet/shared';
import { Tab, Tabs as MuiTabs } from '@mui/material';
import { PassengerSearchGroup } from 'dto/passengers';
import { setPassengersFilterTab } from 'features/passengers/passengersActions';
import { passengersTabSelector } from 'features/passengers/passengersSelectors';
import { TransTitle } from 'i18n/trans/title';
import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { PassengersSearchByTripForm } from 'routes/Passengers/Search/PassengersSearchByTripForm';
import { useDispatch, useSelector } from 'store/utils';

interface PassengersSearchProps {}

export const PassengersSearch: FC<PassengersSearchProps> = () => {
  const dispatch = useDispatch();
  const tab = useSelector(passengersTabSelector);

  const tabs = useMemo(
    () => ({
      [PassengerSearchGroup.TRIPS]: {
        component: <PassengersSearchByTripForm />,
        label: <TransTitle i18nKey="searchByTrips" />,
      },
    }),
    []
  );

  const handleChangeTab = useCallback(
    (event: SyntheticEvent<Element, Event>, value) => {
      event.stopPropagation();
      dispatch(setPassengersFilterTab(value));
    },
    [dispatch]
  );

  return (
    <SearchForm
      title={
        <MuiTabs value={tab} onChange={handleChangeTab}>
          {Object.values(PassengerSearchGroup).map((group) => (
            <Tab key={group} value={group} label={tabs[group].label} />
          ))}
        </MuiTabs>
      }
    >
      {tabs[tab].component}
    </SearchForm>
  );
};
