import { createSelector, selector } from 'store/utils';

export const selectReAccommodateCurrentTripsList = selector(
  (state) => state.reAccommodate.currentTripsList
);
export const reAccommodateCurrentTripsListSelector = createSelector(
  selectReAccommodateCurrentTripsList
);

export const selectReAccommodateActiveStep = selector(
  (state) => state.reAccommodate.activeStep
);
export const reAccommodateActiveStepSelector = createSelector(
  selectReAccommodateActiveStep
);

export const selectReAccommodateNewTripsFilter = selector(
  (state) => state.reAccommodate.newTripsFilter
);
export const reAccommodateNewTripsFilterSelector = createSelector(
  selectReAccommodateNewTripsFilter
);

export const selectReAccommodateNewTripsList = selector(
  (state) => state.reAccommodate.newTripsList
);
export const reAccommodateNewTripsListSelector = createSelector(
  selectReAccommodateNewTripsList
);

export const selectReAccommodateNewDetailedTrips = selector(
  (state) => state.reAccommodate.newDetailedTrips
);
export const reAccommodateNewDetailedTripsSelector = createSelector(
  selectReAccommodateNewDetailedTrips
);
