import { useLocale } from '@fleet/shared';

export const weekDays = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
] as const;

export const useWeekdaysOptions = () => {
  const locale = useLocale();

  const weekdays = weekDays.map((day, i) => ({
    value: day,
    label: locale.localize?.day(i, { width: 'abbreviated' }),
  }));

  return [...weekdays.slice(1), weekdays[0]];
};
